import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { PaymentInstructionType, PaymentInstructionTypeLabel } from '@ptg-member/features/payee-detail/types/enums';
import { PaymentType } from '@ptg-processing/features/payroll-calendar-container/types/enums/payroll-deduction-payee.enum';
import { PaymentErrorListComponent } from '@ptg-shared/transaction/components/payment-error-list/payment-error-list.component';
import * as fromReducer from '@ptg-reducers';
import { BaseComponent } from '@ptg-shared/components';
import { ModuleKey } from '@ptg-shared/constance/permission.const';
import {
  DATE_FORMAT,
  DEFAULT_FULL_DATETIME,
  DEFAULT_PAGE_SIZE,
  SORT_TYPE,
  TransactionPaymentResponseStatus,
} from '@ptg-shared/constance/value.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Align, Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { Option } from '@ptg-shared/controls/select/select.component';
import { SidebarOpenedStatus } from '@ptg-shared/layout/constance/layout.const';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { Breadcrumb, FunctionButtonConfig, FunctionButtonData } from '@ptg-shared/types/models/breadcrumb.model';
import { isNumeric } from '@ptg-shared/utils/common.util';
import { getDateString, getTimeZone } from '@ptg-shared/utils/string.util';
import { DateTime } from 'luxon';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { RegisterFilterComponent } from '../../components/register-filter/register-filter.component';
import {
  ChipType,
  OverpaidStatus,
  PaymentTransactionStatus,
  QueryExportRegister,
  RegisterListQuery,
  ReissueState,
  TransactionRegister,
  TransactionType,
} from '../../services/models/register.model';
import { exportTransactionRegister, getSummaryListAction } from '../../store/actions/register.action';
import { RegisterState } from '../../store/reducers/transaction-register.reducer';
import { registerSelector } from '../../store/selectors/register.selector';
import { DeductionPayeeRunType } from '@ptg-processing/features/deduction-payees/types/enums';

const datePipe = new DatePipe('en-US');

@Component({
  selector: 'ptg-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent extends BaseComponent {
  readonly SidebarOpenedStatus = SidebarOpenedStatus;
  readonly PaymentInstructionType = PaymentInstructionType;
  readonly DATE_FORMAT = DATE_FORMAT;
  readonly getDateString = getDateString;
  @ViewChild(RegisterFilterComponent) registerFilterComponent?: RegisterFilterComponent;

  pageName = 'ptg-register';
  notFoundMessage = 'No Transactions to Display';

  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Transaction Register',
    },
  ];

  columns: Column[] = [
    {
      name: 'status',
      width: '20px',
    },
    {
      name: 'benefitPeriod',
      header: {
        title: 'Benefit Period',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'transactionTypeText',
      header: {
        title: 'Transaction Type',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'transactionDate',
      header: {
        title: 'Transaction Date',
      },
      sortable: true,
      truncate: true,
      type: ColumnType.DateTime,
      templateArgs: {
        format: DATE_FORMAT,
        emptyString: '-',
      },
    },
    {
      name: 'recipient',
      header: {
        title: 'Recipient',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'paymentMethod',
      header: {
        title: 'Payment Method',
      },
      sortable: true,
      truncate: true,
      cell: (row: Row & TransactionRegister) => {
        return PaymentType[row.paymentMethod!] ?? '-';
      },
    },
    {
      name: 'amount',
      header: {
        title: 'Amount',
      },
      // type: ColumnType.Decimal,
      templateArgs: { unit: '$', decimal: 2, accountingFormat: true },
      sortable: true,
      truncate: true,
      align: Align.Right,
    },
    {
      name: 'datePosted',
      header: {
        title: 'Date Posted',
      },
      sortable: true,
      truncate: true,
      // type: ColumnType.DateTime,
      templateArgs: {
        format: DATE_FORMAT,
      },
    },
  ];

  functionButtons: FunctionButtonConfig[] = [
    {
      buttonName: 'Export',
      icon: 'file_download',
      classInput: 'add-button',
      isDisabled: false,
    },
  ];

  FILTER_ACTION = {
    ADD: 'add',
    LOAD: 'load',
  };

  filterOptions: { iconName: string; label: string; value: string }[] = [
    {
      iconName: 'add_circle',
      label: 'New Filter',
      value: this.FILTER_ACTION.ADD,
    },
    {
      iconName: 'upload',
      label: 'Load Filter',
      value: this.FILTER_ACTION.LOAD,
    },
  ];

  errorMsg: string = '';
  isLoading: boolean = true;
  registerData: (TransactionRegister & Row & { errorMessage?: string })[] = [];
  sortInfo?: Sort;
  totalRecords!: number;
  pageSize: number = DEFAULT_PAGE_SIZE;
  pageNumber: number = FIRST_PAGE;
  currentFund: any;
  selectedFilter: string = this.filterOptions[0].value;
  sidebarOpenedStatus: SidebarOpenedStatus = SidebarOpenedStatus.Opened;

  message = '';
  bannerType: BannerType = BannerType.Hidden;
  expandedControl = new FormControl(false);
  expanedFilter = true;
  hideComponentForFirstTime = true;
  payeeRecordId?: string;

  clientModuleName?: string;
  maxLength: number = 1;
  isConfigOST: boolean = false;
  constructor(
    public dialog: MatDialog,
    private store: Store<RegisterState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.pipe(select(fromReducer.selectCurrentFundState), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.pageSize = el.defaultPageSize ?? DEFAULT_PAGE_SIZE;
      this.currentFund = el;
      this.clientModuleName = el?.modules.find((el) => el.moduleKey === ModuleKey.Employers)?.clientModuleName ?? '';
    });

    this.pageSize =
      Number(sessionStorage.getItem(this.currentFund.key + '-' + this.pageName + '-pageSize')) === 0
        ? this.pageSize
        : Number(sessionStorage.getItem(this.currentFund.key + '-' + this.pageName + '-pageSize'));

    this.getRegisterSelector();
    this.checkSidebarState();
    this.handleRouteParams();
    this.getCurrentActiveMenuItem();
  }

  handleRouteParams() {
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        if (params.memberId) {
          this.payeeRecordId = params.memberId;
          this.expanedFilter = !this.expanedFilter;
          this.getData();
        }
      });
  }

  checkSidebarState() {
    this.store
      .select(fromLayoutReducer.selectSidebarOpenedStatusState)
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe((sidebarOpenedStatusState) => {
        this.sidebarOpenedStatus = sidebarOpenedStatusState;
      });
  }

  private _getSortInfo() {
    let sortType = SORT_TYPE.DESC;
    let sortField = '';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortField = this.sortInfo.active;
      if (sortField === 'benefitPeriod') {
        sortField = 'endDate';
      }
      sortType = this.sortInfo.direction === 'desc' ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }
    if (sortField) {
      sortField =
        sortField[0].toUpperCase() +
        (sortField.includes('Text') ? sortField.substring(1, sortField.length - 4) : sortField.substring(1));
    }

    return [sortField, sortType];
  }

  private _generateQuery(sortField: string, sortType: number) {
    const dateFormat = 'yyyy-MM-dd';
    const defaultTransactionDateFrom = datePipe.transform(DateTime.now().minus({ year: 1 }).toJSDate(), dateFormat)!;
    const query: RegisterListQuery = {
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortNames: sortField ? [sortField] : [],
      sortType,
      transactionDateFrom: defaultTransactionDateFrom,
      payeeRecordId: this.payeeRecordId,
      isOverpaid: null
    };
    const registerFilters = this.registerFilterComponent?.filterForm.value;
    if (registerFilters) {
      query.transactionTypes = (registerFilters.selectedTransactionTypes || []).map(({ value }: Option) => value);
      query.paymentMethods = (registerFilters.selectedPaymentTypes || []).map(({ value }: Option) => value);
      query.benefitEntityIds = (registerFilters.selectedBenefitTypes || []).map(({ value }: Option) => value.entityId);
      query.statusList = (registerFilters.transactionStatus || []).map(({ value }: Option) => value);
      query.payableDateFrom = registerFilters.payableDateFrom
        ? datePipe.transform(registerFilters.payableDateFrom, dateFormat) ?? undefined
        : undefined;
      query.payableDateTo = registerFilters.payableDateTo
        ? datePipe.transform(registerFilters.payableDateTo, dateFormat) ?? undefined
        : undefined;
      query.transactionDateFrom = registerFilters.transactionDateFrom
        ? datePipe.transform(registerFilters.transactionDateFrom, dateFormat) ?? defaultTransactionDateFrom
        : defaultTransactionDateFrom;
      query.transactionDateTo = registerFilters.transactionDateTo
        ? datePipe.transform(registerFilters.transactionDateTo, dateFormat) ?? undefined
        : undefined;
      query.paymentAmount = isNumeric(registerFilters.paymentAmount)
        ? registerFilters.paymentAmount ?? undefined
        : undefined;
        query.transactionId = isNumeric(registerFilters.transactionId) 
        ? registerFilters.transactionId ?? undefined
        : undefined;
      query.checkNumber = (this.isConfigOST ? registerFilters.checkNumber : isNumeric(registerFilters.checkNumber)) ? registerFilters.checkNumber ?? undefined : undefined;
      // User can select to filter by <Overpaid flag>
      // Export Transactions: Add Overpaid
      query.isOverpaid = registerFilters.isOverpaid === OverpaidStatus.Yes ? true : (
        registerFilters.isOverpaid === OverpaidStatus.No ? false : null
      );
      query.returnedDateFrom = registerFilters.returnedDateFrom
        ? datePipe.transform(registerFilters.returnedDateFrom, dateFormat) ?? undefined
        : undefined;
      query.returnedDateTo = registerFilters.returnedDateTo
        ? datePipe.transform(registerFilters.returnedDateTo, dateFormat) ?? undefined
        : undefined;
    }

    return query;
  }

  getData() {
    this.hideComponentForFirstTime = false;
    let [sortField, sortType] = this._getSortInfo();
    const query = this._generateQuery(sortField as string, sortType as number);

    this.store.dispatch(getSummaryListAction({ query }));
  }

  getRegisterSelector() {
    this.store.pipe(select(registerSelector), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.isLoading = el.isLoading || !!el.isExporting;

      if (el.success) {
        this.registerData = el.summaryList.transactions?.map((transaction: TransactionRegister) => {
          const { status, transactionType } = transaction;
          const isStatusPending = status === PaymentTransactionStatus.Pending;
          const [transactionTypeText, chipType, transactionTypeMuni] = this.getTransactionTypeText(transaction);
          const [memoSeeMore, memoValue] = this.getTruncatedMemoValue(transaction);
          const isDeductionPayee = transactionType === TransactionType['Deduction Payee'];
          const [addendaSeeMore, addendaValue] = this.getTruncatedAddenda(transaction, isDeductionPayee);
          const isShowReissueChip = this.isShowReissueChip(transaction);
          const isShowOverpaidChip = transaction.isOverpaid;

          return {
            ...transaction,
            transactionTypeText,
            transactionTypeMuni,
            chipType,
            isShowReissueChip,
            isShowOverpaidChip,
            memoSeeMore,
            memoValue,
            addendaSeeMore,
            addendaValue,
            isDeductionPayee,
            backgroundColor: isStatusPending ? '#F5F5F5' : '',
            italic: isStatusPending,
            errorMessage: this.getRowErrorMessage(transaction),
            statusColumnStyle: this.getStatusColumnStyle(transaction),
            configuredIDMasked: true,
            oneTimeTypeText: this.getPaymentInstructionTypeLabel(transaction),
            alternatePayee: transaction.alternatePayeeNames
            ?.map((alternatePayee) => {
              return alternatePayee.firstName + ' ' + alternatePayee.lastName;
            })
            .join(',\n'),
          };
        });
        this.totalRecords = el.summaryList.total;
        this.maxLength = el.summaryList.maxLength;
        this.isConfigOST = el.summaryList.isOst;
      } else if (el.error) {
        this.errorMsg = el.error.statusText;
      }
    });
  }

  private isShowReissueChip(transaction: TransactionRegister) {
    return (
      (transaction.transactionType === TransactionType['Recurring'] ||
        transaction.transactionType === TransactionType['One-Time'] ||
        transaction.transactionType === TransactionType['Deduction Payee']) &&
      transaction.status === PaymentTransactionStatus['Voided - Not Reissued']
      // Fix bug #159333: If Pending Reissue transaction is Overpaid, show only Overpaid
      && !(transaction.isOverpaid && transaction.reissueState !== ReissueState.Reissued && transaction.reissueState !== ReissueState.ProcessingReissue)
    );
  }

  getPaymentInstructionTypeLabel(transaction: TransactionRegister) {
    if(transaction.oneTimeType) {
      return transaction.oneTimeType;
    }
    if (transaction.paymentInstructionType === PaymentInstructionType.CorrectionOthers) {
      return PaymentInstructionTypeLabel[PaymentInstructionType.Correction];
    }
    return PaymentInstructionTypeLabel[transaction.paymentInstructionType];
  }

  getTruncatedMemoValue(transaction: TransactionRegister): [boolean, string] {
    const memos = (transaction.checkMemos || [])
      .map(({ memo }) => memo)
      .concat((transaction.memo || []).map(({ note }) => note));
    for (const memo of memos) {
      if (memo.length > 10) {
        return [true, memos[0]];
      }
    }
    return [false, memos.join('\n')];
  }

  getTruncatedAddenda(transaction: TransactionRegister, isDeductionPayee: boolean): [boolean, string] {
    if (transaction.paymentMethod !== PaymentType['Direct Deposit'] || !isDeductionPayee) {
      return [false, ''];
    }
    const addendas = (transaction.payeeAddenda || [])
      .map(({ value }) => value)
      .filter((value) => !!value)
      .join(' ');
    return [addendas.length > 10, addendas];
  }

  getTransactionTypeText(transaction: TransactionRegister): [string, number, string?, string?] {
    if (transaction.transactionType === TransactionType.Adjustment) {
      return ['Adjustment', ChipType.Adjustment];
    }
    if (transaction.transactionType === TransactionType['One-Time']) {
      return ['One-Time', ChipType['One-Time']];
    }
    if (transaction.deductionRunType === DeductionPayeeRunType.MuniRefund) {
      return ['Deduction Payee', ChipType.MuniRefund, `${this.clientModuleName}`];
    }

    return [TransactionType[transaction.transactionType], ChipType.Other];
  }

  getRowErrorMessage(transaction: TransactionRegister) {
    if (transaction.status === PaymentTransactionStatus['Voided - Not Reissued']) {
      if (transaction.paymentResponseStatus === TransactionPaymentResponseStatus.Null) {
        return `This transaction has errors because ${transaction.reason}`; // @TODO show reason
      }
      if (transaction.paymentResponseStatus === TransactionPaymentResponseStatus.Rejected) {
        return 'This transaction has errors. Please click on the icon to view details.';
      }
    }
    if (transaction.status === PaymentTransactionStatus.Posted) {
      if (transaction.paymentResponseStatus === TransactionPaymentResponseStatus.Null) {
        return 'This transaction has been posted with possible error. Please click on the icon to view details.';
      }
    }

    return undefined;
  }

  getStatusColumnStyle(transaction: TransactionRegister) {
    const { status, reason, voidedBy, voidedTime, paymentResponseStatus } = transaction;
    const statusColumnStyle: Record<string, string | boolean> = {};

    if (status === PaymentTransactionStatus.Posted) {
      if (paymentResponseStatus === null || paymentResponseStatus === TransactionPaymentResponseStatus.Accepted) {
        Object.assign(statusColumnStyle, { iconName: 'check_circle', color: '#408BF9', tooltipMessage:'Posted' });
      } else if (paymentResponseStatus === TransactionPaymentResponseStatus.Null) {
        Object.assign(statusColumnStyle, {
          iconName: 'pending',
          color: '#F2A354',
          tooltipMessage: 'Posted with possible error',
        });
      }
    } else if (status === PaymentTransactionStatus['Voided - Not Reissued']) {
      Object.assign(statusColumnStyle, { iconName: 'remove_circle', color: '#EF4C53' });
      if (paymentResponseStatus === TransactionPaymentResponseStatus.Rejected) {
        statusColumnStyle.hasError = true;
        statusColumnStyle.hasClickEvent = true;
        statusColumnStyle.tooltipMessage = 'Voided due to rejection by Paying agent. Please click to review in detail.';
      } else if (paymentResponseStatus === TransactionPaymentResponseStatus.Null || voidedBy) {
        statusColumnStyle.hasError = true;
        statusColumnStyle.tooltipMessage = `Voided by ${voidedBy} at ${this.datePipe.transform(getDateString(voidedTime ?? ""), DEFAULT_FULL_DATETIME)}, due to ${reason?.join(', ')}.`;
      }
    } else if (status === PaymentTransactionStatus.Adjusted) {
      Object.assign(statusColumnStyle, { iconName: 'settings_backup_restore', color: '#F2A354' });
    }

    return statusColumnStyle;
  }

  showErrorPopup(event: TransactionRegister) {
    this.dialog.open(PaymentErrorListComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        isPaymentLevel: true,
        transactionId: event.id,
        payrollRunId: event.payrollRunId,
      },
    });
  }

  onChangeSort(event: Sort) {
    this.sortInfo = event;
    this.getData();
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + '-' + this.pageName + '-pageSize', this.pageSize.toString());

    this.getData();
  }

  showBanner({ bannerType, message }: { bannerType: BannerType; message: string }) {
    this.bannerType = bannerType;
    this.message = message;
    this.getData();
  }

  applyFilter() {
    if (!this.registerFilterComponent?.filterForm.valid) {
      return;
    }
    this.expanedFilter = !this.expanedFilter;
    this.expandedControl.setValue(false);
    this.getData();
  }

  resetFilter() {
    this.registerFilterComponent?.resetForm();
    this.expanedFilter = !this.expanedFilter;
    this.expandedControl.setValue(false);
    this.getData();
  }

  emitFunction(event: FunctionButtonData): void {
    if (event.buttonName === this.functionButtons[0].buttonName) {
      this.handleExport();
    }
  }

  handleExport() {
    if (!this.registerFilterComponent?.filterForm.valid) {
      return;
    }
    let [sortField, sortType] = this._getSortInfo();
    const query = this._generateQuery(sortField as string, sortType as number);
    const request: QueryExportRegister = {
      ...query,
      fileName: `TransactionRegister_${datePipe.transform(new Date(), 'MMddyy_HHmmss')}`,
      timeZone: getTimeZone() || '',
    };
    this.store.dispatch(exportTransactionRegister({ request }));
  }

  getCurrentActiveMenuItem() {
    this.store.select(fromLayoutReducer.selectCurrentMenuGroupsState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentMenuGroups) => {
        if (!this.payeeRecordId) {
          return;
        }
        const selectedMenuGroups = (currentMenuGroups || [])?.find((item) => item.isSelected)?.menu || [];
        const url = this.router.url;
        for (const group of selectedMenuGroups) {
          const menuItems = group.menuItems || [];
          for (const menuItem of menuItems) {
            if (url.startsWith(menuItem.routerLink)) {
              this.listBreadcrumbs[0].name = menuItem.name;
              return;
            }
          }
        }
      });
  }
}
