import { Sort } from '@angular/material/sort';

import { PaymentInstructionType } from '@ptg-member/features/payee-detail/types/enums';
import { BatchResponseStatus, TransactionPaymentResponseStatus, SortType } from '@ptg-shared/constance';

import { PayeeFilterOptions } from '../../types/models';
import { PaymentErrorList } from '@ptg-shared/transaction/services/models/register.model';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';

export interface PayingAgentWarning {
  holdWarning: boolean;
  submitToBank: boolean;
  batchHasError: boolean;
  dateTimeSubmitted: string;
  transmissionJobNumber: number;
  interchangeControlNumber: number;
  numberOfErrorTransactions: number;
  receivedAcknowledgementFile: boolean;
  batchResponseStatus: BatchResponseStatus;
}

export interface PayrollRunSummary {
  calendarId: string;
  calendarName: string;
  canReset: boolean;
  payrollRunId?: string;
  numberOfPayees: number;
  numberOfPayments: number;
  numberOfDirectDeposits: number;
  numberOfChecks: number;
  totalGross: number;
  totalDeductions: number;
  totalNet: number;
  postedAt: string;
  payableDate: string;
  benefitPeriod: string;
  depositDate: string;
  checkDate: string;
  warrantNumber: string;
  checkMemo: string;
  achSent: boolean;
  checksPrinted: boolean;
  auditTrails: AuditTrail[];
  state: PayrollRunStep;
  canEditCheckNumber: boolean;
  showDepositDate: boolean; // at least one DirectDeposit transaction with value >0
  showCheckDate: boolean; // at least one Check transaction with value >0
  showNegativeNetAmount: boolean;
  earlierThanCutOffDate: boolean;
  missingPaymentAddress: boolean;
  hasErrorResponse: boolean;
  hasRecurringPayment: boolean;
  isDisplayBenefitPeriod: boolean;
  submittedToBank: boolean;
  payingAgentWarning: PayingAgentWarning;
  showChecksOnly: boolean;
  showChangeAndZacOnly: boolean;
  hasPayments: boolean;
  ostConfig: boolean;
  isShowStartingCheckNumber: boolean;
  checkNumberConfig: CheckNumberConfig;
  allNetPaymentsZero: boolean;
  isOst?: boolean;
  ostWaitingTime?: number;
  endingChar: string;
  isAnyPICheckMethod?: boolean;
  useNewCheckNumberConfig?: boolean;
}

export enum PayrollRunStep {
  Review,
  SendWarrant,
  IssuePayment,
  Finalize,
  Canceled,
  AchSent,
  ChecksPrinted,
  // Payroll's processing step
  SubmittedToBankWithPartiallyError = 237,
  BankResponseReceived = 239,
  ResetPayrollSuccess = 240,
  ResetPayrollFailed = 241,
  Cancelling = 242,
  CancellingFailed = 243,
  AutoFinalizeFailed = 244,
  SubmittedToBank = 245,
  FinalizeFailed = 246,
  SubmittingToBankFailed = 247,
  GeneratingEdiFailed = 248,
  SubmittingToBank = 249,
  InitializingFailed = 250,
  UpdateTransactionFailed = 251,
  Initializing = 252,
  Resetting = 253,
  UpdatingTransaction = 254,
  Finalizing = 255,
}

export interface AuditTrail {
  action: number;
  createdBy: string;
  createdTime: string;
}

export interface GetPayrollRunSummaryRequest {
  filter?: PayeeFilterOptions;
  payrollRunId: string;
  selectedState?: PayrollRunStep;
  paymentInstructionId?: string;
}

export interface BankAccountDetail {
  bankId: string;
  fundBankAccountId: string;
  bankName: string;
  accountNumber: string;
  accountType: number;
  types: string[];
  startingCheckNumber: number;
  payingAgent: boolean;
  bankDisabled: boolean;
  routingNumber: string;
}

export interface BankAccountDetailsReponse {
  assignments: BankAccountDetail[];
  useBenefitPayeeBankAssignments: boolean;
}

export interface GetPayrollRunListPaymentRequest {
  sortType: SortType;
  sortField: string;
  payrollRunId: string;
  pageIndex: number;
  pageSize: number;
  filter?: PayeeFilterOptions;
  isOnReview: boolean;
  id?: string;
}

export interface GetPayrollRunListPaymentResponse {
  total: number;
  headerLabel: string;
  payrollRunPaymentInstructions: PaymentInstruction[];
}

export interface PaymentInstruction {
  id: string;
  paymentTransactionId: string;
  paymentInstructionId: string;
  paymentInstructionSnapshotId: string;
  order: number;
  payableDate: string;
  benefitStartDate: string;
  benefitEndDate: string;
  transactionId: number;
  payeeId: string;
  benefitId: string;
  payeeNameRecipient: string;
  benefitType: string;
  paymentType: number;
  responseStatus: TransactionPaymentResponseStatus;
  splitPayments: number;
  checkNumber: any;
  gross: number;
  totalDeduction: number;
  netPayment: number;
  noDs: boolean;
  paymentAddressCode: string;
  isMissingAddress: boolean;
  representativePayee: string;
  payeeRecordId: string;
  payeeEntityId: string;
  piStatus: PayStatus;
  diffGross: number;
  diffDeds: number;
  diffNet: number;
  isFirst: boolean;
  paymentInstructionType: PaymentInstructionType;
}

export interface UpdateTransactionsRequest {
  payrollRunId: string;
}

export interface GetPayrollRunStateResponse {
  state: PayrollRunStep;
}

export interface GetPayrollRunStateResponse {
  response: PaymentErrorList[];
}

export interface SubmitToBankRequest {
  payrollRunId: string;
  bankId?: string;
  excludeCheckDate?: boolean;
  excludeDepositDate?: boolean;
}

export interface SubmitToBankRequestQuery {
  ostWaitingTime?: number;
  isOst?: boolean;
  includeMuniRefundTransactions?: boolean;
}

export enum EdiErrorType {
  Batch,
  Payment,
  Internal,
  Segment,
  Field,
  ACH,
}

export interface GetTotalPayeesPaymentsRequest {
  payrollRunId: string;
  currentStep: PayrollRunStep;
}

export interface GetTotalPayeesPaymentsResponse {
  totalPayeeAndPayment: Record<string, any>;
}

export interface EditCheckNumberRequest {
  payrollRunId: string;
  transactionId: string;
  checkNumber: string;
  subsequentUpdate: boolean;
}
export interface GetTotalDeductionsRequest {
  payrollRunId: string;
  payrollStep: PayrollRunStep;
}

export interface GetTotalDeductionResponse {
  deductionItems: DeductionItem[];
  ganishmentsAmount: number;
  qdrosAmount: number;
  adjustmentsAmount: number;
  totalAmount: number;
}

export interface DeductionItem {
  deductionType: DeductionType;
  totalAmount: number;
  deductionSubTypeItems: DeductionSubType[];
}

export interface DeductionSubType {
  codeName: string;
  amount: number;
}

export enum DeductionType {
  Tax = 1,
  Insurance = 2,
  Garnishment = 3,
  Adjustment = 4,
  Qdro = 5,
  Others = 255,
}

export enum PayStatus {
  Suspend = 1,
  Pending = 2,
  Terminated = 3,
  Finalized = 4,
  Cancelled = 5,
  Closed = 6,
}

export interface CancelPayrollRequest {
  payrollRunId: string;
}

export interface CancelPayrollResponse {}

export interface NextPayrollScreenState {
  step?: number;
  changesOnly?: boolean;
  zacsOnly?: boolean;
  excludePayment?: boolean;
  checksOnly?: boolean;
  errorsOnly?: boolean;
  negativeNetAmount?: boolean;
  nodsOnly?: boolean;
  pageNumber?: number;
  sortInfo?: Sort;
  searchContent?: string;
}

export interface MissingFillingForms {
  taxReady: boolean;
  missingFillingForms: string[];
}

export interface SearchPayee {
  id?: string;
  payrollBenefitId?: string;
  paymentInstructionId?: string;
  benefitId?: string;
  payeeEntityId?: string;
  payeeRecordId?: string;
  benefitType?: string;
  payeeId?: string;
  payeeSsn?: string;
  organizationEIn?: string;
  order?: string;
  payeeNameRecipient?: string;
  checkNumber?: string;
  transactionId?: string;
}

export interface GetSearchPayeesRequest {
  pageNumber?: number;
  pageSize?: number;
  sortNames?: string;
  sortType?: number;
  payrollRunId?: string;
  searchContent: string;
  step?: PayrollRunStep;
}

export interface GetSearchPayeesResponse {
  payments: SearchPayee[];
  total: number;
}

export interface ACHFile {
  fileContent: any;
  fileName: string;
  fileType: string;
  description: string;
  tags: any;
}

export interface GetACHFileRequest {
  payrollRunId: string;
}

export interface GetACHFileResponse {
  achFile: ACHFile;
}

export interface ClearAuditTrailRequest {
  payrollRunId: string;
  payrollRunState: PayrollRunStep;
}

export interface CheckNumberConfig {
  prefix: any;
  suffix: string;
  maxLength: number;
  fieldName: string;
  configType: number;
}

export interface EditStartCheckNumberReviewRequest {
  payrollRunId: string;
  startingCheckNumber: string;
}

export interface EditStartCheckNumberDeductionRequest {
  payrollRunId: string;
  startingCheckNumber: any;
  isViewOndeDuctionPayees?: boolean
}

//#region #159728
export enum ValidateCheckDepositDateMessageType {
  // AC2: Enhance validation when checking on ACH Sent/Check Printed (non-paying agent flow)
  NonPayingAgent = 2,
  // AC3: Enhance validation when Submit to Bank
  OSTOrPayingAgent = 3
}

export interface ValidateCheckDepositDateRequest {
  dialogData?: {
    title: string;
    type: ConfirmType;
  };
  messageType: ValidateCheckDepositDateMessageType;
  checkDate?: string;
  depositDate?: string;
  payrollRunId: string;
}

export interface ValidateCheckDepositDateResponse {
  isValid: boolean;
  message: string;
}
//#endregion
