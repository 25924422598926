import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormBuilder,
  FormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Store } from '@ngrx/store';
import { EditPaymentStatusService } from '@ptg-member/features/payee-detail/services';
import * as EditPaymentStatusActions from '@ptg-member/features/payee-detail/store/actions';

import { BaseComponent } from '@ptg-shared/components';
import { BUTTON_LABEL_CLOSE, BUTTON_LABEL_OK } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { MY_DATE } from '@ptg-shared/controls/datepicker/datepicker.component';
import { Option } from '@ptg-shared/controls/select/select.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { deepClone, showCancelDialog } from '@ptg-shared/utils/common.util';
import { getDateString, isEmpty, localeCompare } from '@ptg-shared/utils/string.util';
import { DateTime } from 'luxon';

import { BehaviorSubject, combineLatest, Observable, of, Subscription, timer } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { GetPayrollSettingsResponse } from '../../services/models';

import {
  EditPaymentStatusInputData,
  EditPaymentStatusOutputData,
  GetLastPayrollCyclesResponse,
  GetValidateChangeLastPayrollCycleResponse,
  LastPayrollCycleDetail,
  ParsedEditPaymentStatusInputData,
  Reason,
  SaveAdjustmentStatusRequest,
  SaveEditPaymentStatusRequest,
} from '../../services/models/edit-payment-status.model';
import { getPaymentEarningsDetailState, getPayrollSettingsSelector, PayeeDetailState } from '../../store';
import {
  getEditPaymentStatusInitRulesSelector,
  getLastPayrollCyclesSelector,
  getSuspensionReasonsSelector,
  getValidationOTPPeriodOverlappedSelector,
  getValidationOTPRunSelector,
  getValidationQDROLinkedSelector,
  saveAdjustmentStatusSelector,
  saveEditPaymentStatusSelector,
} from '../../store/selectors/edit-payment-status.selectors';
import { getPayeePaymentSelector } from '../../store/selectors/payee-payment.selector';

import {
  EditPaymentStatusActionName,
  EditPaymentStatusValidationMessage,
  PaymentInfoAdjustmentType,
  PaymentInstructionType,
  PaymentStatusAdjustmentType,
  PayStatus,
  ValidateChangeLastPayrollCycleErrorType,
  ValidateChangeLastPayrollCycleErrorTypeMessage,
} from '../../types/enums';
import { PayeePayment, PayeePaymentType } from '../../types/models';

import { EditPaymentStatusComponentService } from './edit-payment-status.component.service';
import { ONE_TIME_PAYMENT_INSTRUCTION_TYPE } from '../../types/constants/payment-info-tab.constant';
import { checkApiValidatorGetMessage } from '@ptg-shared/validators/checkApi.validator';

@Component({
  selector: 'ptg-edit-payment-status',
  templateUrl: './edit-payment-status.component.html',
  styleUrls: ['./edit-payment-status.component.scss'],
  providers: [EditPaymentStatusComponentService],
})
export class EditPaymentStatusComponent extends BaseComponent {
  // Dialog Title
  dialogTitle = '';

  // Banner
  bannerType: BannerType = BannerType.Hidden;
  message = '';

  // Loading
  isLoading = true;
  errorOccurs = false;

  // FormGroup, FormControl
  private editForm =
    this.data?.paymentInstruction?.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment
      ? this.editPaymentStatusComponentService.getInitFormGroupForAdjustment
      : this.editPaymentStatusComponentService.getInitFormGroup;

  // Component visibility conditions
  isShownSuspendCheckbox = false;
  isShownSuspendReason = false;
  isShownCreatePayment = false;
  isShownTerminateCheckbox = false;
  isShownTerminationReason = false;
  isRecurring = false;

  // Disable/Enable conditions
  isDisabledSuspendCheckbox = false;
  isDisabledTerminateCheckbox = false;
  isDisabledCreatePaymentToggle = false;

  //for adjustment
  isShowAdjComponent = false;
  isDisabledApprovedCheckbox = false;
  isDisabledRejectedCheckbox = false;
  isShowAdjReason = false;
  adjustmentError = '';
  // Dropdown List data
  suspensionReasonList: Option[] = [];
  lastPayrollCycleDateList: Option[] = [];
  terminationReasonList: Option[] = [];

  // Data from Payment Information screen
  private payrollSettings?: GetPayrollSettingsResponse;
  private paymentDetails: PayeePayment[] = [];
  // TODO (Next Sprint): private paymentHeader?: any
  private totalEarning = 0;
  private totalDeduction = 0;

  // Data for Init FormGroup Rules Depends on selected PI
  private isBothInitialAndRecurringSuspended$ = new BehaviorSubject(false);
  private isQDRORelatedHasTerminated$ = new BehaviorSubject(false);
  private isQDRORelatedHasSuspended = false;
  private isPeriodOverlapped = false;
  private isQDROLinked = false;

  // Check variable
  private subscriptionGetConfirmOTP?: Subscription;
  lastPayrollCycles?: GetLastPayrollCyclesResponse;

  readonly neededSelectors = [
    this.payeeDetailStore.select(getLastPayrollCyclesSelector).pipe(filter((res) => !!res)),
    this.payeeDetailStore.select(getEditPaymentStatusInitRulesSelector),
    this.payeeDetailStore.select(getValidationQDROLinkedSelector),
    this.payeeDetailStore.select(getValidationOTPPeriodOverlappedSelector),
    this.payeeDetailStore.select(getSuspensionReasonsSelector),
    this.payeeDetailStore.select(getPayrollSettingsSelector),
    this.payeeDetailStore.select(getPayeePaymentSelector),
    this.payeeDetailStore.select(getPaymentEarningsDetailState),
  ];

  get isDisabledBenefitEndDate() {
    return !!(
      this.data.paymentInstruction?.benefitEndDate &&
      this.data.dateOfDeath &&
      new Date(this.data.paymentInstruction.benefitEndDate).getTime() === new Date(this.data.dateOfDeath).getTime()
    );
  }

  get maxEndDate() {
    // If the benefit payee had another recurring payment instruction with Start Date in the future and status = Pending/Suspend,
    // End date of the current Payment Instruction must be less than the next Payment's Instruction's Start Date
    if (!this.lastPayrollCycles) {
      return MY_DATE.maxDate;
    }
    if (!this.isRecurring || !this.lastPayrollCycles?.nextPIStartDate) {
      return MY_DATE.maxDate;
    }
    return DateTime.fromISO(getDateString(this.lastPayrollCycles?.nextPIStartDate), { zone: 'utc' }).toJSDate();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: EditPaymentStatusInputData,
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly payeeDetailStore: Store<PayeeDetailState>,
    private readonly editPaymentStatusComponentService: EditPaymentStatusComponentService,
    private readonly dialogRef: MatDialogRef<EditPaymentStatusComponent, EditPaymentStatusOutputData>,
    private editPaymentStatusService: EditPaymentStatusService,
    private layoutService: LayoutService,
  ) {
    super();
  }

  compareWithFunction = (o1: LastPayrollCycleDetail, o2: LastPayrollCycleDetail) => {
    return o1?.dateDetailId === o2?.dateDetailId;
  };

  get benefitEndDateControl(): FormControl {
    return this.editForm.get('benefitEndDate') as FormControl;
  }

  onSave(): void {
    // If there are any mandatory fields that are blank, the system displays the inline error messages under those fields: "<Placeholder> is required.".
    this.editForm.markAllAsTouched();
    if (this.editForm.invalid) {
      return;
    }

    if (this.editForm.pending) {
      setTimeout(() => {
        this.onSave();
      }, 1000)
      return;
    }

    //* (4) Rule for Adjustment only
    if (this.isShowAdjComponent) {
      this.onFlowSaveAdjustment();
      return;
    }

    const suspensionReasonEmpty = this.isSuspendedControl.value && !this.suspensionReasonChipArray.length;
    if (suspensionReasonEmpty) {
      this.suspensionReasonControl.setErrors({ required: true });
      return;
    }

    // Validation Rules
    const validationMessage: {
      message: EditPaymentStatusValidationMessage;
      targetControlName: string;
    } | null = this.getValidationMessage();
    if (validationMessage !== null) {
      this.editForm.get(validationMessage.targetControlName)?.setErrors({ customError: validationMessage.message });
      return;
    }

    // User updated both Suspend and Terminate = unchecked,
    // Either [Benefit End Date] < Start Date of current benefit period OR [Last Payroll Cycle] < current benefit period
    const currentBenefitStartDate = this.lastPayrollCycles?.currentPayrollCycle?.startDate;
    if (
      !this.isSuspendedControl.value &&
      !this.isTerminatedControl.value &&
      currentBenefitStartDate &&
      ((this.benefitEndDateControl.value &&
        this.dateGreater(currentBenefitStartDate, this.benefitEndDateControl.value)) ||
        (this.lastPayrollCycleControl.value?.endDate &&
          this.dateGreater(currentBenefitStartDate, this.lastPayrollCycleControl.value?.endDate)))
    ) {
      this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        disableClose: true,
        data: {
          text: 'The payment instruction had expired and can not be unsuspend. Please extend the Last Payroll Cycle or confirm the termination.',
          type: ConfirmType.Warning,
          title: 'Attention',
          cancelButtonTitle: BUTTON_LABEL_OK,
          hideConfirmButton: true,
        },
      });
      return;
    }

    // case branching for OTP and Recurring
    if (!this.data.isOneTimePayment) {
      this.onFlowRecurring();
    }
    //* (3) Rule for One-Time Payment Instruction only
    else {
      this.onFlowOTP();
    }
  }

  private onFlowRecurring(): void {
    // Confirmation pop-up Rules
    const confirmationMessage: string = this.getConfirmationMessage();
    if (confirmationMessage) {
      const confirmPopupDialog = this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        autoFocus: false,
        disableClose: true,
        data: {
          title: 'Confirmation',
          text: confirmationMessage,
          type: ConfirmType.Attention,
        },
      });

      confirmPopupDialog
        .afterClosed()
        .pipe(take(1))
        .subscribe((confirmed: boolean | undefined) => {
          if (confirmed) {
            this.getSaveEditPaymentStatus();
          }
        });
      return;
    }

    this.getSaveEditPaymentStatus();
  }

  private onFlowOTP(): void {
    if (this.subscriptionGetConfirmOTP) {
      this.subscriptionGetConfirmOTP.unsubscribe();
      this.subscriptionGetConfirmOTP = undefined;
    }
    this.payeeDetailStore.dispatch(
      EditPaymentStatusActions.getValidationOTPRunAction({
        paymentInstructionId: this.data.paymentInstruction?.id ?? '',
        benefitTypeOptionId: this.data.headerBenefits?.benefitTypeOptionId ?? '',
        isSuspend: this.isSuspendedControl.value,
      }),
    );
    this.subscriptionGetConfirmOTP = this.payeeDetailStore.select(getValidationOTPRunSelector).subscribe((el) => {
      if (el?.payload) {
        this.subscriptionGetConfirmOTP?.unsubscribe();
        this.subscriptionGetConfirmOTP = undefined;
        if (el?.payload.isValid) {
          if (el?.payload.isShowConfirm && !this.isSuspendedControl.value) {
            const confirmPopupDialog = this.dialog.open(ConfirmPopupComponent, {
              panelClass: 'confirm-popup',
              autoFocus: false,
              disableClose: true,
              data: {
                title: 'Confirmation',
                text: 'This initial payment was suspended past its scheduled payroll run. Would you like to have the amount recalculated so that it can be reflected in the next benefit period?',
                type: ConfirmType.Attention,
              },
            });

            confirmPopupDialog
              .afterClosed()
              .pipe(take(1))
              .subscribe((confirmed: boolean | undefined) => {
                if (confirmed) {
                  this.getSaveEditPaymentStatusOTP(true);
                } else {
                  this.getSaveEditPaymentStatusOTP(false);
                }
              });
          } else {
            this.getSaveEditPaymentStatusOTP();
          }
        } else if (this.isSuspendedControl.value) {
          // Show error under isSuspendedControl
          this.isSuspendedControl.setErrors({
            customError: 'Cannot suspend this One-Time Payment as the payment processing is already completed.',
          });
        }
      }
    });
  }

  private async onFlowSaveAdjustment() {
    this.adjustmentError = '';
    const isSelectedApproved = this.editForm.get('isApproved')?.value;
    const isSelectedRejected = this.editForm.get('isRejected')?.value;
    const actionName = isSelectedApproved ? 'approve' : 'reject';
    if (!isSelectedApproved && !isSelectedRejected) {
      this.adjustmentError = 'You must select one of the checkboxes to proceed.';
      return;
    }

    if (isSelectedApproved && this.data.paymentInstruction?.paymentInstructionHistoryId) {
      let validObj = await this.editPaymentStatusService.checkAdjustmentApproveValid(this.data.paymentInstruction?.paymentInstructionHistoryId ?? '', this.data.paymentInstruction?.id ?? '', this.data?.memberId ?? '', this.data?.headerBenefits?.benefitTypeOptionId ?? '').toPromise();
      if (validObj && !validObj?.isValid) {
        this.adjustmentError = validObj?.message ? validObj.message : 'The net position of Total Deductions cannot exceed original Gross amount.';
        return;
      }
    }
   
    // Confirmation pop-up Rules
    const confirmationMessage = `Are you sure you want to ${actionName} this adjustment? This action cannot be undone.`;
    if (confirmationMessage) {
      const confirmPopupDialog = this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        autoFocus: false,
        disableClose: true,
        data: {
          title: 'Confirmation',
          text: confirmationMessage,
          type: ConfirmType.Attention,
        },
      });

      confirmPopupDialog
        .afterClosed()
        .pipe(take(1))
        .subscribe((confirmed: boolean | undefined) => {
          if (confirmed) {
            const status = isSelectedApproved
              ? PaymentStatusAdjustmentType.Approved
              : PaymentStatusAdjustmentType.Rejected;
            const reason = this.editForm.get('adjReason')?.value;
            this.updateAdjustmentStatus(status, reason);
          }
        });
    }
  }

  private updateAdjustmentStatus(status: PaymentStatusAdjustmentType, reason: string): void {
    const adjustmentId = this.data.paymentInstruction?.id ?? '';
    const body: SaveAdjustmentStatusRequest = {
      adjustmentId,
      status,
      reason,
    };

    this.payeeDetailStore.dispatch(EditPaymentStatusActions.saveAdjustmentStatusAction({ body }));
  }

  onCancel(): void {
    showCancelDialog(this.dialog, this.dialogRef);
  }

  onAddChip(): void {
    const selectedSuspensionReasonOption = this.suspensionReasonList.find(
      (item) => item.value === this.suspensionReasonControl.value,
    );
    if (!selectedSuspensionReasonOption) {
      return;
    }
    this.suspensionReasonChipArray.push(this.fb.control(selectedSuspensionReasonOption));
    this.suspensionReasonControl.reset();
    selectedSuspensionReasonOption.isHide = true;
  }

  onRemoveChip(index: number): void {
    this.suspensionReasonChipArray.at(index).value.isHide = false;
    this.suspensionReasonChipArray.removeAt(index);
  }

  onSelectApproved(event: boolean) {
    this.isDisabledRejectedCheckbox = event;
    this.adjustmentError = '';
  }

  onSelectRejected(event: boolean) {
    this.isDisabledApprovedCheckbox = event;
    this.isShowAdjReason = event;
    this.adjustmentError = '';
  }

  private initFormControlValueChanges(): void {
    const paymentType = this.data.paymentInstruction?.paymentType;
    const paymentStatus = this.data.paymentInstruction?.payStatus;

    if (
      paymentType === PaymentInstructionType.Recurring ||
      paymentType === PaymentInstructionType.AllRecurring ||
      ONE_TIME_PAYMENT_INSTRUCTION_TYPE.includes(paymentType as PaymentInstructionType)
    ) {
      // Display only for Recurring and One-time Payment Instruction
      this.isShownSuspendCheckbox = true;
      // Suspend checkbox disable changes
      this.suspendCheckboxChanges();
    }

    if (paymentType === PaymentInstructionType.Recurring) {
      // Display only for Recurring Payment Instruction. System displays this field only if the current [Status] of the Payment Instruction = "Suspended"
      this.isShownCreatePayment = paymentStatus === PayStatus.Suspended;
      // Create Payment toggle disable & reset condition changes
      this.createPaymentToggleChanges();

      // Display only for Recurring Payroll Payment Instruction
      this.isShownTerminateCheckbox = true;
      /**
       * Last Payroll Cycle visibility & mandatory validation
       * Termination Reason dropdown list visibility & mandatory validation
       */
      this.terminateCheckboxValueChanges();
    }

    /**
     * Suspension Reason dropdown list visibility & mandatory validation
     * Terminate checkbox disable changes
     */
    this.suspensionComponentsValueChanges();

    /**
     * Initial Payment's Suspend check box: If tax configuration had not been configured in the Recurring PI, system block the action and display inline error msg: 
     * "Payment Address or Withholding instruction of the recurring payment had not been configured, please update the Payroll setting of the Recurring payment before unsuspend.
     */
    if (paymentType === PaymentInstructionType.InitialPayment) {
      this.isSuspendedControl.addAsyncValidators(
        checkApiValidatorGetMessage(
          this.editPaymentStatusService.checkValidUnsuspend,
          'isSuspended',
          undefined,
          {
            params: {
              benefitCode: this.data.headerBenefits?.benefitId,
              isCheckPaymentAddressValid: true
            },
          },
          () => {
            this.editForm.updateValueAndValidity();
          },
        )
      )
    }
  }

  private suspendCheckboxChanges(): void {
    combineLatest([
      of(this.data.paymentInstruction?.paymentType),
      this.isSuspendedControl.valueChanges.pipe(startWith(false)),
      this.isTerminatedControl.valueChanges.pipe(startWith(false)),
      this.suspensionReasonChipArray.valueChanges.pipe(startWith([])),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([paymentType, isSuspended, isTerminated, suspensionReasonChipList]) => {
          /**
           * Disable "Suspend" checkbox if one of the following conditions is correct:
           * - There are suspension reason chips displayed.
           * - If the "Terminate" checkbox is checked (only applicable for Recurring Payment Instruction).
           * - If the current benefit Payee had an Initial Payment Instruction and a Recurring Payment Instruction both at "Suspended" status, disable this check box Suspend in the Recurring instruction.
           */
          this.isDisabledSuspendCheckbox =
            (isSuspended && !!suspensionReasonChipList.length) ||
            (paymentType === PaymentInstructionType.Recurring && isTerminated);
        },
      );

    this.isSuspendedControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.isCreatePaymentControl.setErrors(null, { emitEvent: false });
      this.isTerminatedControl.setErrors(null, { emitEvent: false });
    });
  }

  private createPaymentToggleChanges(): void {
    combineLatest([
      this.isSuspendedControl.valueChanges.pipe(startWith(false)),
      this.isTerminatedControl.valueChanges.pipe(startWith(false)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([isSuspended, isTerminated]: [boolean, boolean]) => {
        /**
         * If user either check "Suspend" checkbox OR "Terminate" checkbox, disable and update this toggle to default value.
         * If both "Suspend" and "Terminate" checkboxes are unchecked, enable the toggle.
         * Default value: OFF.
         */
        this.isDisabledCreatePaymentToggle = isSuspended || isTerminated;
        this.isCreatePaymentControl.reset(false);
      });

    this.isCreatePaymentControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.isSuspendedControl.setErrors(null, { emitEvent: false });
      this.isTerminatedControl.setErrors(null, { emitEvent: false });
    });
  }

  private terminateCheckboxValueChanges(): void {
    this.isTerminatedControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((isTerminated: boolean) => {
      this.isSuspendedControl.setErrors(null, { emitEvent: false });
      this.isCreatePaymentControl.setErrors(null, { emitEvent: false });

      /**
       * Display if "Terminate" checkbox is checked
       * Validate mandatory for "Last Payroll Cycle" & "Termination Reason" dropdown lists if "Terminate" checkbox is checked
       */
      this.isShownTerminationReason = isTerminated;
      this.lastPayrollCycleControl.setValidators(isTerminated ? Validators.required : null);
      this.terminationReasonControl.setValidators(isTerminated ? Validators.required : null);
      this.lastPayrollCycleControl.updateValueAndValidity();
      this.terminationReasonControl.updateValueAndValidity();
      this.lastPayrollCycleControl.markAsUntouched();
      this.terminationReasonControl.markAsUntouched();
    });
  }

  validateLastPayrollCycle(errorTypes: ValidateChangeLastPayrollCycleErrorType[]): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const lastPayrollCycleId = this.lastPayrollCycleControl.value?.dateDetailId;
      const benefitEndDateValue = this.benefitEndDateControl.value;
      if (
        !lastPayrollCycleId ||
        !benefitEndDateValue ||
        !this.dateGreater(benefitEndDateValue, DateTime.now().toISO())
      ) {
        return of(null);
      }
      return timer(300).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.editPaymentStatusService
              .getValidateChangeLastPayrollCycle(this.data.paymentInstruction?.id ?? '', {
                benefitEndDate: DateTime.fromISO(benefitEndDateValue).startOf('day').toISO({ includeOffset: false }),
                lastPayrollCycleId,
              })
              .pipe(
                map((res: GetValidateChangeLastPayrollCycleResponse) => {
                  if (!res?.errorTypes?.length) {
                    return null;
                  }
                  const error = errorTypes.find((errorType) => res.errorTypes.includes(errorType));
                  if (isEmpty(error)) {
                    return null;
                  }
                  return {
                    customError: (ValidateChangeLastPayrollCycleErrorTypeMessage as any)[
                      ValidateChangeLastPayrollCycleErrorType[error!]
                    ],
                  };
                }),
                catchError(({ error }) => {
                  return of({ customError: error?.errorMessage });
                }),
              ),
        ),
      );
    };
  }

  private suspensionComponentsValueChanges(): void {
    combineLatest([
      this.isQDRORelatedHasTerminated$,
      this.isSuspendedControl.valueChanges,
      this.suspensionReasonChipArray.valueChanges.pipe(startWith([])),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([isQDRORelatedHasTerminated, isSuspended, chipList]: [boolean, boolean, any[]]) => {
        // Display "Suspend Reason dropdown list" if "Suspend" checkbox is checked
        this.isShownSuspendReason = isSuspended;
        // Validate mandatory for "Suspend Reason" dropdown list if "Suspend" checkbox is checked
        this.suspensionReasonControl.setValidators(isSuspended && !chipList.length ? Validators.required : null);
        this.suspensionReasonControl.updateValueAndValidity({ emitEvent: false });
        this.suspensionReasonControl.markAsUntouched();
        /**
         * Disable "Terminate" checkbox if one of the following conditions is correct:
         * The "Suspend" checkbox is checked. To enable this checkbox, uncheck the Suspend checkbox.
         * OR if the current Benefit = "QDRO" and the related primary payee has [Payment Instruction Status] = "Terminated".
         */
        this.isDisabledTerminateCheckbox =
          isSuspended || isQDRORelatedHasTerminated || !this.lastPayrollCycles?.hasAnyFinalizedRecurringPayment;
      });
  }

  private getParsedInputData(): void {
    const parsedInputData: ParsedEditPaymentStatusInputData | null =
      this.editPaymentStatusComponentService.getParsedSavedData(this.data);

    if (!parsedInputData) {
      return;
    }
    const {
      isSuspended = false,
      isTerminated = false,
      isPending = false,
      savedReasonIdList = [],
      lastPayrollCycle = null,
    } = parsedInputData;

    this.isSuspendedControl.setValue(isSuspended);
    this.isTerminatedControl.setValue(isTerminated);
    if (this.isRecurring) {
      this.lastPayrollCycleControl.setValue(lastPayrollCycle);
    }

    // Append saved suspension reason chip list
    if (isSuspended) {
      const reasonOptionList: Option[] = this.suspensionReasonList
        .filter((item) => savedReasonIdList.includes(item.value))
        .map((item) => {
          item.isHide = true;
          return item;
        });

      this.suspensionReasonChipArray.clear();
      reasonOptionList.forEach((item) => {
        // Only add unique item to the chip array
        this.suspensionReasonChipArray.push(this.fb.control(item));
      });
    }
    // Append saved termination reason
    else if (isTerminated || isPending) {
      const selectedTerminationReasonOption: Option | undefined = this.terminationReasonList.find(
        (item) => item.value === savedReasonIdList[0],
      );
      this.terminationReasonControl.setValue(selectedTerminationReasonOption?.value);
    }
  }

  ngOnInit(): void {
    // Get initial popup title
    this.dialogTitle =
      this.data.paymentInstruction?.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment
        ? 'Edit Status'
        : this.editPaymentStatusComponentService.getDialogTitle(this.data.paymentInstruction?.paymentType);
    this.isRecurring = this.data.paymentInstruction?.paymentType === PaymentInstructionType.Recurring;
    if (this.isRecurring) {
      this.benefitEndDateControl.setValue(this.data.paymentInstruction?.benefitEndDate);
      this.benefitEndDateControl.addAsyncValidators(
        this.validateLastPayrollCycle([ValidateChangeLastPayrollCycleErrorType.AFinalPaymentIsBeingProcessed]),
      );
      this.lastPayrollCycleControl.addAsyncValidators(
        this.validateLastPayrollCycle([
          ValidateChangeLastPayrollCycleErrorType.LastPayrollCycleCannotBeSetupToPayInAdvanceInCaseOfFutureBenefitEndDate,
          ValidateChangeLastPayrollCycleErrorType.AFinalPaymentIsBeingProcessed,
        ]),
      );
    }
    this.isShowAdjComponent =
      this.data.paymentInstruction?.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment;
    if (this.isShowAdjComponent) {
      this.isLoading = false;
      this.layoutService.showLoading = this.isLoading;
      // Listen to save action on Edit Adjustment Status screen
      this.selectUpdateAdjustmentStatusState();
    } else {
      // Get suspension & termination reasons data
      this.selectGetReasonsState();
      this.getReasonsByPaymentStatus();

      // Initialize FormGroup ValueChanges
      this.initFormControlValueChanges();

      // Loading indicator visibility
      this.loadingIndicatorFromStates();

      // Get last payroll cycle dates data
      if (this.isRecurring) {
        this.getLastPayrollCycles();
      }

      // Get form validation by payment instruction data
      this.selectGetEditPaymentStatusInitRulesState();
      this.getEditPaymentStatusInitRules();
      this.selectGetValidationQDROLinkedState();
      this.getValidationQDROLinked();
      this.selectGetValidationOTPPeriodOverlappedState();
      this.getValidationOTPPeriodOverlapped();

      // Get View Details data
      // !NOTICE: No need to dispatch action to GET unless there're any update to clear state values
      this.selectGetPayrollSettingsDetailState();
      this.selectPayeePaymentDetailState();
      this.selectPaymentEarningsDetailState();

      // Listen to save action on Edit Payment Status screen
      this.selectSaveEditPaymentStatusState();
    }
  }

  private getEditPaymentStatusInitRules(): void {
    const paymentInstructionId = this.data.paymentInstruction?.id ?? '';
    this.payeeDetailStore.dispatch(
      EditPaymentStatusActions.getEditPaymentStatusInitRulesAction({ paymentInstructionId }),
    );
  }

  private selectGetEditPaymentStatusInitRulesState(): void {
    this.payeeDetailStore
      .select(getEditPaymentStatusInitRulesSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (!response?.success || !response?.payload) {
          return;
        }

        const {
          isBothInitialAndRecurringSuspended = false,
          isQDRORelatedHasTerminated = false,
          isQDRORelatedHasSuspended = false,
        } = response.payload;

        this.isBothInitialAndRecurringSuspended$.next(isBothInitialAndRecurringSuspended);
        this.isQDRORelatedHasTerminated$.next(isQDRORelatedHasTerminated);
        this.isQDRORelatedHasSuspended = isQDRORelatedHasSuspended;
      });
  }

  private getValidationQDROLinked(): void {
    const paymentInstructionId = this.data.paymentInstruction?.id ?? '';
    this.payeeDetailStore.dispatch(EditPaymentStatusActions.getValidationQDROLinkedAction({ paymentInstructionId }));
  }

  private selectGetValidationQDROLinkedState(): void {
    this.payeeDetailStore
      .select(getValidationQDROLinkedSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (!response?.success || !response?.payload) {
          return;
        }
        const { isQDROLinked = false } = response.payload;
        this.isQDROLinked = isQDROLinked;
      });
  }

  private getValidationOTPPeriodOverlapped(): void {
    const paymentInstructionId = this.data.paymentInstruction?.id ?? '';
    this.payeeDetailStore.dispatch(
      EditPaymentStatusActions.getValidationOTPPeriodOverlappedAction({ paymentInstructionId }),
    );
  }

  private selectGetValidationOTPPeriodOverlappedState(): void {
    this.payeeDetailStore
      .select(getValidationOTPPeriodOverlappedSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (!response?.success || !response?.payload) {
          return;
        }
        const { isPeriodOverlapped = false } = response.payload;
        this.isPeriodOverlapped = isPeriodOverlapped;
      });
  }

  private getReasonsByPaymentStatus(): void {
    this.payeeDetailStore.dispatch(
      EditPaymentStatusActions.getReasonsAction({
        statuses: [PayStatus.Suspended, PayStatus.Terminated]
      }),
    );
  }

  private selectGetReasonsState(): void {
    this.payeeDetailStore
      .select(getSuspensionReasonsSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (!response?.success || !response?.payload) {
          return;
        }

        const reasonListMapper = (item: Reason) => ({
          value: item.reasonOptionId ?? '',
          displayValue: item.reasonOptionDescription ?? '',
        });
        const reasonListSort = (a: Option, b: Option) => localeCompare(a.displayValue, b.displayValue);

        // Sorting: sort by <Short Description> of the [<PI Status> Reason] in alphabetical order (a-z).
        const suspensionReasonList = deepClone(response.payload.suspensionReasonsResponse?.reasons ?? [])
          .map(reasonListMapper)
          .sort(reasonListSort);
        const terminationReasonList = deepClone(response.payload.terminationReasonsResponse?.reasons ?? [])
          .map(reasonListMapper)
          .sort(reasonListSort);

        this.suspensionReasonList = suspensionReasonList;
        this.terminationReasonList = terminationReasonList;
      });
  }

  onChangeBenefitEndDate(event: any) {
    this.getLastPayrollCycles();
  }

  private selectGetPayrollSettingsDetailState(): void {
    this.payeeDetailStore
      .select(getPayrollSettingsSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (!response?.success || !response?.payload) {
          return;
        }

        this.payrollSettings = response.payload;
      });
  }

  private selectPayeePaymentDetailState(): void {
    this.payeeDetailStore
      .select(getPayeePaymentSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (!response?.success || !response?.payload) {
          return;
        }

        this.paymentDetails = response.payload.payments ?? [];
      });
  }

  private selectPaymentEarningsDetailState(): void {
    this.payeeDetailStore
      .select(getPaymentEarningsDetailState)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (!response?.success || !response?.payload) {
          return;
        }

        const parsedEarningDetailData = this.editPaymentStatusComponentService.getEarningDetailData(
          this.data.paymentInstruction?.paymentType,
          response.payload,
        );
        if (!parsedEarningDetailData) {
          return;
        }
        const { grossCurrent, totalDeductions } = parsedEarningDetailData;
        this.totalEarning = grossCurrent ?? 0;
        this.totalDeduction = totalDeductions ?? 0;
      });
  }

  private getValidationMessage(): {
    message: EditPaymentStatusValidationMessage;
    targetControlName: string;
  } | null {
    const isSuspendCheckboxUnchecked = this.isSuspendedControl.value === false;
    const isTerminateCheckboxUnchecked = this.isTerminatedControl.value === false;
    const isCreatePaymentToggleOn = this.isCreatePaymentControl.value;
    const paymentType = this.data.paymentInstruction?.paymentType;

    if (isSuspendCheckboxUnchecked && this.isBothInitialAndRecurringSuspended$.value) {
      return {
        message: EditPaymentStatusValidationMessage.UnsuspendTheInitialPaymentFirst,
        targetControlName: 'isSuspended',
      };
    }
    //* (1) Rule for both One-Time Payment Instruction & Recurring Payment Instruction
    // If 'suspend' checkbox is unchecked, system checks if input value of the following fields:
    // For Recurring Payment Instruction, if one of the following fields are null "Form", "Additional Withholding", "Payment Address" are null, display error message under the "Suspend" checkbox "Payment Address or Withholding instruction had not been configured, please update Payroll setting before unsuspend."
    if (
      isSuspendCheckboxUnchecked &&
      paymentType === PaymentInstructionType.Recurring &&
      (isEmpty(this.payrollSettings?.federalForm) || isEmpty(this.payrollSettings?.paymentAddress))
    ) {
      return {
        message: EditPaymentStatusValidationMessage.AddressOrWithholdingInstructionNotConfigured,
        targetControlName: 'isSuspended',
      };
    }
    // If 'suspend' checkbox is unchecked, system checks if input value of the following fields:
    // For One-Time Payment Instruction, if "Payment Address" is null, display error message under the "Suspend" checkbox "Payment Address had not been configured, please update Payroll setting before unsuspend."
    if (
      isSuspendCheckboxUnchecked &&
      ONE_TIME_PAYMENT_INSTRUCTION_TYPE.includes(paymentType as PaymentInstructionType) &&
      isEmpty(this.payrollSettings?.paymentAddress)
    ) {
      return {
        message: EditPaymentStatusValidationMessage.PaymentAddressNotConfigured,
        targetControlName: 'isSuspended',
      };
    }
    // If 'suspend' checkbox is unchecked, system checks if input value of the following fields:
    // In Payment detail: if there is item linked to a Deposit account with [Active] = No, display error message under the "Suspend" checkbox "A deposit account is inactive, please update Split Payment before unsuspend."
    
    if (isSuspendCheckboxUnchecked && this.paymentDetails.some((item) => item?.depositAccount?.isActive === false && item.paymentType !== PayeePaymentType.Check)) {
      return {
        message: EditPaymentStatusValidationMessage.DepositAccountInactive,
        targetControlName: 'isSuspended',
      };
    }
    // If 'suspend' checkbox is unchecked, system checks if input value of the following fields:
    // If Total Earning < Total Deduction, display error message under the "Suspend" checkbox: "The net payment is less than zero, please adjust earning and deduction before unsuspend."
    if (isSuspendCheckboxUnchecked && this.totalEarning < this.totalDeduction) {
      return {
        message: EditPaymentStatusValidationMessage.NetPaymentLessThanZero,
        targetControlName: 'isSuspended',
      };
    }

    //* (2) Rule for Recurring Payment Instruction only
    // If the current benefit = "QDRO" and the related primary payee's Recurring Payment has [Payment Instruction Status] = "Suspended" and user leaves 2 checkboxes Suspend and Terminate uncheck, system shows the inline error message under the terminate checkbox "The primary payee benefit is suspended".
    if (
      paymentType === PaymentInstructionType.Recurring &&
      this.isQDRORelatedHasSuspended &&
      isSuspendCheckboxUnchecked &&
      isTerminateCheckboxUnchecked
    ) {
      return {
        message: EditPaymentStatusValidationMessage.PrimaryPayeeBenefitSuspended,
        targetControlName: 'isTerminated',
      };
    }
    // If toggle <Create Payment for Suspended period> = ON, system checks if there is any One-Time Payment instruction of the same payee that met all the following conditions, then display the inline error message:
    // Error message: "Another Correction One-Time Payment had been created for the suspended period"
    if (paymentType === PaymentInstructionType.Recurring && isCreatePaymentToggleOn && this.isPeriodOverlapped) {
      return {
        message: EditPaymentStatusValidationMessage.AnotherCorrectionOneTimePaymentCreated,
        targetControlName: 'isCreatePayment',
      };
    }
    return null;
  }

  private getConfirmationMessage(): string {
    //* (1) Rule for Recurring Payment Instruction only
    // If user checks the checkbox Suspend or Terminate and the current benefit is for primary payee who linked to any benefit having [Is QDRO] = "Yes", system will show a confirmation pop-up as below. Otherwise, there is no confirmation displayed if edit from alternate payee (payee has benefit with [Is QDRO] = "Yes").
    const isSuspended = this.isSuspendedControl.value;
    const isTerminated = this.isTerminatedControl.value;
    if (isSuspended && !isTerminated && this.isQDROLinked) {
      return this.editPaymentStatusComponentService.getConfirmationMessage(EditPaymentStatusActionName.Suspend);
    } else if (!isSuspended && isTerminated && this.isQDROLinked) {
      return this.editPaymentStatusComponentService.getConfirmationMessage(EditPaymentStatusActionName.Terminate);
    }
    //* TODO (Next Sprint): (2) Rule for Adjustment only
    //* TODO (Next Sprint): (3) Rule for One Time Payment Instruction only
    else {
      return '';
    }
  }

  private getSaveEditPaymentStatusOTP(isRecaculateInitialPayment?: boolean): void {
    const paymentInstructionId = this.data.paymentInstruction?.id ?? '';
    const body: SaveEditPaymentStatusRequest = this.getSaveBodyRequest();
    body.payrollBenefitId = this.data.paymentInstruction?.payrollBenefitId ?? '';
    body.isRecaculateInitialPayment = isRecaculateInitialPayment;
    body.isSuspend = this.isSuspendedControl.value;
    body.status = PayStatus.Suspended;
    delete body.isCreatePayment;
    delete body.lastPayrollCycleDateDetailId;
    delete body.reason;
    this.payeeDetailStore.dispatch(
      EditPaymentStatusActions.saveEditPaymentStatusAction({ paymentInstructionId, body, isOTP: true }),
    );
  }

  private getSaveEditPaymentStatus(): void {
    const paymentInstructionId = this.data.paymentInstruction?.id ?? '';
    const body: SaveEditPaymentStatusRequest = this.getSaveBodyRequest();

    this.payeeDetailStore.dispatch(
      EditPaymentStatusActions.saveEditPaymentStatusAction({ paymentInstructionId, body }),
    );
  }

  private setDefaultLatestPayrollCycle(defaultLastPayrollCycle?: LastPayrollCycleDetail) {
    if (!this.benefitEndDateControl) {
      this.lastPayrollCycleControl.setValue(null);
      return;
    }
    if (this.benefitEndDateControl.dirty) {
      this.lastPayrollCycleControl.setValue(defaultLastPayrollCycle);
      this.lastPayrollCycleControl.markAsTouched();
    }
  }

  private selectSaveEditPaymentStatusState(): void {
    this.payeeDetailStore
      .select(saveEditPaymentStatusSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response?.isLoading === false) {
          // Response to View Detail screen to show banner message
          this.dialogRef.close({
            isSuccess: !!response.success,
            isReversing: this.data.isReversing,
            isTerminated: this.isTerminatedControl.value,
            lastPayrollCycle: this.lastPayrollCycleControl.value,
            isUncheckStatus: !this.isTerminatedControl.value && !this.isSuspendedControl.value,
          });
        }
      });
  }

  private selectUpdateAdjustmentStatusState(): void {
    this.payeeDetailStore
      .select(saveAdjustmentStatusSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response?.isLoading === false) {
          // Response to View Detail screen to show banner message
          this.dialogRef.close({
            isSuccess: !!response.success,
            isReversing: this.data.isReversing,
            isRejected: this.isRejectedControl.value,
          });
        }
      });
  }

  private clearStates(): void {
    this.payeeDetailStore.dispatch(EditPaymentStatusActions.clearGetEditPaymentStatusInitRulesState());
    this.payeeDetailStore.dispatch(EditPaymentStatusActions.clearGetValidationQDROLinkedState());
    this.payeeDetailStore.dispatch(EditPaymentStatusActions.clearGetValidationOTPPeriodOverlappedState());
    this.payeeDetailStore.dispatch(EditPaymentStatusActions.clearGetReasonsState());
    this.payeeDetailStore.dispatch(EditPaymentStatusActions.clearGetLastPayrollCyclesState());
    this.payeeDetailStore.dispatch(EditPaymentStatusActions.clearSaveEditPaymentStatusState());
    this.payeeDetailStore.dispatch(EditPaymentStatusActions.clearSaveAdjustmentStatusState());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.clearStates();
    this.subscriptionGetConfirmOTP?.unsubscribe();
    this.subscriptionGetConfirmOTP = undefined;
  }

  get isSuspendedControl(): FormControl {
    return this.editForm.get('isSuspended') as FormControl;
  }

  get isTerminatedControl(): FormControl {
    return this.editForm.get('isTerminated') as FormControl;
  }

  get isCreatePaymentControl(): FormControl {
    return this.editForm.get('isCreatePayment') as FormControl;
  }

  get suspensionReasonControl(): FormControl {
    return this.editForm.get('suspensionReason') as FormControl;
  }

  private loadingIndicatorFromStates(): void {
    const selectors = this.neededSelectors;
    if (!this.isRecurring) {
      selectors.splice(0, 1);
    }
    combineLatest(selectors)
      .pipe(
        tap((responseList) => {
          this.isLoading = responseList.some((res) => res?.isLoading);
          this.layoutService.showLoading = this.isLoading;
          this.errorOccurs = responseList.some((res) => res?.error);
        }),
        filter((res) => !this.isLoading),
        map((responseList, index) => {
          if (this.isRecurring) {
            this.lastPayrollCycles = deepClone(responseList[0]?.payload ?? {}) as GetLastPayrollCyclesResponse;
            const lastPayrollCycleDateList = this.lastPayrollCycles?.lastPayrollCycle ?? [];
            // add saved payroll cycle on init
            if (!index) {
              const savedLastPayrollCycle = this.editPaymentStatusComponentService.getParsedSavedData(this.data)
                ?.lastPayrollCycle;
              const selectedLastPayrollCycleDateList = lastPayrollCycleDateList.find(
                (item) => item.dateDetailId === savedLastPayrollCycle?.dateDetailId,
              );
              if (savedLastPayrollCycle && !selectedLastPayrollCycleDateList) {
                lastPayrollCycleDateList.push(savedLastPayrollCycle as LastPayrollCycleDetail);
              }
            }
            this.lastPayrollCycleDateList = this.getLastPayrollCycleDateList(lastPayrollCycleDateList);
            const defaultLastPayrollCycle = this.getDefaultPayrollCycle();
            this.setDefaultLatestPayrollCycle(defaultLastPayrollCycle);
          }

          // Get input data
          if (!index) {
            this.getParsedInputData();
          } else if (this.isRecurring) {
            const latestRecurringFinalizedPayrollCycle = this.lastPayrollCycles!.latestRecurringFinalizedPayrollCycle;
            if (
              latestRecurringFinalizedPayrollCycle?.endDate &&
              this.dateGreater(latestRecurringFinalizedPayrollCycle.endDate, this.benefitEndDateControl.value)
            ) {
              const payrollCycleContainBenefitEndDate = this.lastPayrollCycles!.payrollCycleContainBenefitEndDate;
              const startDate =
                payrollCycleContainBenefitEndDate.startDate &&
                DateTime.fromISO(payrollCycleContainBenefitEndDate.startDate)
                  .startOf('day')
                  .toFormat('MM/dd/yyyy', { locale: 'UTC' });
              const endDate =
                payrollCycleContainBenefitEndDate.endDate &&
                DateTime.fromISO(payrollCycleContainBenefitEndDate.endDate)
                  .startOf('day')
                  .toFormat('MM/dd/yyyy', { locale: 'UTC' });
              this.dialog.open(ConfirmPopupComponent, {
                panelClass: 'confirm-popup',
                disableClose: true,
                data: {
                  text: `Benefit End Date belongs to a finalized benefit period ${startDate} - ${endDate}. You may need to manually create an adjustment to make change accordingly.`,
                  type: ConfirmType.Warning,
                  title: 'Attention',
                  cancelButtonTitle: BUTTON_LABEL_CLOSE,
                  hideConfirmButton: true,
                },
              });
            }
          }
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {});
  }

  private getLastPayrollCycleDateList(lastPayrollCycleDateList: LastPayrollCycleDetail[] | null | undefined) {
    return (
      deepClone(lastPayrollCycleDateList ?? [])
        .map((item) => ({
          value: item,
          displayValue: `${
            item.startDate ? DateTime.fromISO(item.startDate).toFormat('MM/dd/yyyy', { locale: 'UTC' }) : ''
          } - ${item.endDate ? DateTime.fromISO(item.endDate).toFormat('MM/dd/yyyy', { locale: 'UTC' }) : ''}`,
        }))
        // Sorting: from the latest finalized payroll onwards
        .sort((a: Option, b: Option) => b.value?.endDate - a.value?.endDate)
    );
  }

  get lastPayrollCycleControl(): FormControl {
    return this.editForm.get('lastPayrollCycle') as FormControl;
  }

  get terminationReasonControl(): FormControl {
    return this.editForm.get('terminationReason') as FormControl;
  }

  get suspensionReasonChipArray(): FormArray {
    return this.editForm.get('suspensionReasonChipList') as FormArray;
  }

  get isApprovedControl(): FormControl {
    return this.editForm.get('isApproved') as FormControl;
  }

  get isRejectedControl(): FormControl {
    return this.editForm.get('isRejected') as FormControl;
  }

  get adjReasonControl(): FormControl {
    return this.editForm.get('adjReason') as FormControl;
  }

  private getLastPayrollCycles(): void {
    const paymentInstructionId = this.data.paymentInstruction?.id ?? '';
    const benefitEndDate = this.benefitEndDateControl.value
      ? DateTime.fromISO(this.benefitEndDateControl.value).toFormat('MM/dd/yyyy', { locale: 'UTC' })
      : undefined;
    this.payeeDetailStore.dispatch(
      EditPaymentStatusActions.getLastPayrollCyclesAction({ paymentInstructionId, benefitEndDate }),
    );
  }

  private getSaveBodyRequest(): SaveEditPaymentStatusRequest {
    const {
      isSuspended,
      isTerminated,
      isCreatePayment,
      lastPayrollCycle,
      terminationReason,
      suspensionReasonChipList = [],
      benefitEndDate,
    } = this.editForm.getRawValue();
    const desiredPaymentStatus: PayStatus | null = this.editPaymentStatusComponentService.getDesiredPaymentStatus(
      isSuspended,
      isTerminated,
      lastPayrollCycle,
      this.data.paymentInstruction,
    );
    const stateReasonIds: string[] | null = this.editPaymentStatusComponentService.getReasonIdList(
      isSuspended,
      isTerminated,
      terminationReason,
      suspensionReasonChipList,
    );
    return {
      paymentInstructionId: this.data.paymentInstruction?.id ?? null,
      benefitSubtypeId: this.data.headerBenefits?.benefitTypeOptionId ?? null,
      stateReasonIds,
      status: desiredPaymentStatus,
      isCreatePayment: isCreatePayment,
      lastPayrollCycleDateDetailId: lastPayrollCycle?.dateDetailId,
      reason: null, // Recurring does not need to assign for this field
      benefitEndDate: benefitEndDate
        ? DateTime.fromISO(benefitEndDate).startOf('day').toISO({ includeOffset: false })
        : undefined,
    };
  }

  private dateGreater(a: string, b: string) {
    if (!b) {
      return false;
    }
    if (!a) {
      return true;
    }
    return DateTime.fromISO(a).startOf('day').toMillis() > DateTime.fromISO(b).startOf('day').toMillis();
  }

  private getDefaultPayrollCycle() {
    const { latestRecurringFinalizedPayrollCycle, payrollCycleContainBenefitEndDateMinusOne } =
      this.lastPayrollCycles ?? {};
    const finalizedPayrollCycleEndDate = DateTime.fromISO(
      latestRecurringFinalizedPayrollCycle?.endDate ?? '',
    ).toMillis();
    const previousPayrollEndDate = DateTime.fromISO(
      payrollCycleContainBenefitEndDateMinusOne?.endDate ?? '',
    ).toMillis();
    const defaultLastPayrollCycle =
      finalizedPayrollCycleEndDate > previousPayrollEndDate
        ? latestRecurringFinalizedPayrollCycle
        : payrollCycleContainBenefitEndDateMinusOne;
    if (
      defaultLastPayrollCycle &&
      !this.lastPayrollCycleDateList.find((item) => item.value.dateDetailId === defaultLastPayrollCycle.dateDetailId)
    ) {
      const currentLastPayrollCycles = this.lastPayrollCycleDateList.map((item) => item.value);
      this.lastPayrollCycleDateList = this.getLastPayrollCycleDateList([
        ...currentLastPayrollCycles,
        defaultLastPayrollCycle,
      ]);
    }
    return defaultLastPayrollCycle;
  }
}
