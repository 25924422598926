import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { DateTime } from 'luxon';

import {
  OrderByType,
  ValidateAdjustmentErrorType,
  CreateGeneralAdjustmentFormArrayKey,
} from '@ptg-member/types/enums/create-general-adjustment.enum';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@ptg-shared/components';
import { isEmpty } from '@ptg-shared/utils/string.util';
import { MAX_VALUE_NUMBER } from '@ptg-shared/constance';
import { Option } from '@ptg-shared/controls/select/select.component';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { InputType } from '@ptg-member/constance/metadataPropertyType.const';
import { MY_DATE } from '@ptg-shared/controls/datepicker/datepicker.component';
import { showBanner, showCancelDialog, showConfirmDialog } from '@ptg-shared/utils/common.util';
import { DeductionType } from '@ptg-processing/features/payroll-calendar-container/types/enums';

import {
  CorrespondingItem,
  GetDeductionTypeRequest,
  CreateGeneralAdjustmentParams,
  CreateGeneralAdjustmentRequest,
  ValidateGeneralAdjustmentParams,
  ValidateGeneralAdjustmentRequest,
  CreateGeneralAdjustmentInputData,
  CreateGeneralAdjustmentOutputData,
  ParsedDeductionSubTypeAndBenefitPeriodData,
} from '../../services/models/create-general-adjustment.model';

import {
  PayeeDetailState,
  getGetDeductionTypesAction,
  clearGetDeductionTypesState,
  getAddGeneralAdjustmentAction,
  getEarningFundingSourcesAction,
  clearAddGeneralAdjustmentState,
  getValidateGeneralAdjustmentAction,
  clearValidateGeneralAdjustmentState,
  clearGetEarningFundingSourcesStateAction,
} from '../../store';

import {
  getDeductionTypeSelector,
  addGeneralAdjustmentSelector,
  validateGeneralAdjustmentSelector,
} from '../../store/selectors/create-general-adjustment.selectors';
import { getEarningFundingSourcesSelector } from '../../store/selectors/edit-funding-source.selector';
import { CreateGeneralAdjustmentComponentService } from './create-general-adjustment.component.service';
import { GetEarningFundingSourcesQueryParams, GetEarningFundingSourcesRequest } from '../../services/models';

@Component({
  selector: 'ptg-create-general-adjustment',
  templateUrl: './create-general-adjustment.component.html',
  styleUrls: ['./create-general-adjustment.component.scss'],
  providers: [CreateGeneralAdjustmentComponentService],
})
export class CreateGeneralAdjustmentComponent extends BaseComponent {
  readonly InputType = InputType;
  readonly CreateGeneralAdjustmentFormArrayKey = CreateGeneralAdjustmentFormArrayKey;
  readonly amountMaxValue = MAX_VALUE_NUMBER;
  readonly amountMinValue = MAX_VALUE_NUMBER * -1;

  // Titles
  dialogTitle = 'New General Adjustment';
  qdroDeductionTitle = 'QDRO';
  fundingSourceTitle = 'Earnings';

  // Banner
  bannerType: BannerType = BannerType.Hidden;
  message = '';

  // Information Banner
  infoBannerType: BannerType = BannerType.Info;
  infoMessage = "Benefit period's Start date and End date must be Start date and End date of certain payroll cycle.";

  // Loading
  isLoading = true;
  errorOccurs = false;

  // [Create General Adjustment] FormGroup
  private createForm = this.createGeneralAdjustmentComponentService.getInitFormGroup;

  // Visibility condition
  isHiddenBenefitPeriod = true;

  isHiddenAddFundingSourceButton = false;
  isHiddenAddInsuranceButton = false;
  isHiddenAddOtherDeductionButton = false;
  isHiddenAddGarnishmentButton = false;
  isHiddenAddQDRODeductionButton = false;

  isShowTaxDeduction = false;
  isShowInsurance = false;
  isShowOtherDeduction = false;
  isShowGarnishment = false;
  isShowQdroDeduction = false;

  // Date values
  currentDate = new Date(); // Used for Accounting Post Date & Transaction Date
  benefitPeriodStartDateValue = MY_DATE.validMinDate; // Used for Accounting Post Date & Transaction Date

  minStartDate = MY_DATE.validMinDate; // Used for Benefit Period Start Date

  minEndDate = MY_DATE.validMinDate; // Used for Benefit Period End Date
  maxEndDate = MY_DATE.maxDate; // Used for Benefit Period End Date

  earningInfoDisplay = {
    grossPayment: 0,
    totalDeductions: 0,
    netPayment: 0,
  };
  fundingSourceOptionList: Option[] = [];
  deductionTypeOptionList: Option[] = [];
  insuranceOptionList: Option[] = [];
  otherDeductionOptionList: Option[] = [];
  garnishmentOptionList: Option[] = [];
  qdroDeductionOptionList: Option[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: CreateGeneralAdjustmentInputData,
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly payeeDetailStore: Store<PayeeDetailState>,
    private readonly createGeneralAdjustmentComponentService: CreateGeneralAdjustmentComponentService,
    private readonly dialogRef: MatDialogRef<CreateGeneralAdjustmentComponent, CreateGeneralAdjustmentOutputData>,
  ) {
    super();
  }

  ngOnInit(): void {
    // Loading indicator visibility
    this.loadingIndicatorFromStates();

    // Get option list for [Funding Sources] dropdown list
    this.getEarningFundingSources();
    this.selectEarningFundingSourcesState();

    // Get option list for All [Deduction Types/Sub-Types] dropdown lists
    this.getDeductionTypes();
    this.selectDeductionTypesState();

    // Selectors of Validate & Submit
    this.selectValidateGeneralAdjustmentState();
    this.selectAddGeneralAdjustmentState();

    // Handle deduction type section visibility & data cleaner
    this.deductionTypeChipsOnChanges();
  }

  async onSave(): Promise<void> {
    //! THIS IS THE ORDER OF VALIDATING RULES
    const isConfirmed = await showConfirmDialog(
      this.dialog,
      "Do you want to adjust the selected Payee's benefit?",
    ).toPromise();

    if (!isConfirmed) return;

    // ​If there are any mandatory fields as blank, system displays the inline error message: “<Placeholder> is required.”.
    // ​If the input value for any input field is not in the defined min/max range, the system displays an inline error message under the error field: “<Placeholder> must be within the range of <Min value> - <Max value>”.
    this.createForm.markAllAsTouched();
    if (this.createForm.invalid) return;

    // If there is no Funding Source or Deduction is added to current Adjustment, system display error banner with message: "At least 1 item of Funding Source or Deduction must be required"
    if (!this.areFundingSourceOrDeductionValid) {
      showBanner.call(this, BannerType.Fail, '', '', {
        customMessage: 'At least 1 item of Funding Source or Deduction must be required.',
      });
      return;
    }

    // Validate [Benefit Period] data
    this.getValidateGeneralAdjustment();
  }

  onCancel(): void {
    showCancelDialog(this.dialog, this.dialogRef);
  }

  onAddDeductionTypeChip(): void {
    const selectedDeductionTypeOption = this.deductionTypeOptionList.find(
      (item) => item.value === this.deductionTypeControl.value,
    );
    if (!selectedDeductionTypeOption) return;

    this.deductionTypeChipFormArray.push(this.fb.control(selectedDeductionTypeOption));
    this.deductionTypeControl.reset();
    selectedDeductionTypeOption.isHide = true;

    // Add new row for the selected deduction one
    switch (selectedDeductionTypeOption.value) {
      case DeductionType.Insurance:
        this.onAddNewRow(
          CreateGeneralAdjustmentFormArrayKey.Insurances,
          'insurance',
          this.insuranceFormArray,
          this.insuranceOptionList,
        );
        break;
      case DeductionType.Others:
        this.onAddNewRow(
          CreateGeneralAdjustmentFormArrayKey.OtherDeductions,
          'otherDeduction',
          this.otherDeductionFormArray,
          this.otherDeductionOptionList,
        );
        break;
      case DeductionType.Garnishment:
        this.onAddNewRow(
          CreateGeneralAdjustmentFormArrayKey.Garnishments,
          'garnishment',
          this.garnishmentFormArray,
          this.garnishmentOptionList,
        );
        break;
      case DeductionType.QDRO:
        this.onAddNewRow(
          CreateGeneralAdjustmentFormArrayKey.QDRODeductions,
          'qdroDeduction',
          this.qdroDeductionFormArray,
          this.qdroDeductionOptionList,
        );
        break;
      default:
        break;
    }
  }

  onRemoveDeductionTypeChip(index: number, deductionType: DeductionType): void {
    this.deductionTypeChipFormArray.at(index).value.isHide = false;
    this.deductionTypeChipFormArray.removeAt(index);
    this.setVisibilityForAddNewButtons(true, deductionType);
  }

  onChangeRowOption(
    formArrayKey: CreateGeneralAdjustmentFormArrayKey,
    formGroupKey: string,
    formArray: FormArray,
    changedFormGroup: AbstractControl,
  ): void {
    const isShownAddNewButton = this.createGeneralAdjustmentComponentService.changeUniqueRowOption(
      formArrayKey,
      formGroupKey,
      formArray,
      changedFormGroup,
    );
    this.setVisibilityForAddNewButtons(isShownAddNewButton, formArrayKey);
  }

  onAddNewRow(
    formArrayKey: CreateGeneralAdjustmentFormArrayKey,
    formGroupKey: string,
    formArray: FormArray,
    optionList: Option[],
  ): void {
    formArray.push(
      this.createGeneralAdjustmentComponentService.getNewUniqueRow(
        formArrayKey,
        formGroupKey,
        formArray.getRawValue(),
        optionList,
      ),
    );
  }

  onRemoveRow(
    formArrayKey: CreateGeneralAdjustmentFormArrayKey,
    formGroupKey: string,
    formArray: FormArray,
    index: number,
  ): void {
    this.createGeneralAdjustmentComponentService.changeOnRemoveUniqueRow(formArrayKey, formGroupKey, formArray, index);
    this.recalculatingPreviewAmount(formArrayKey);
    this.setVisibilityForAddNewButtons(true, formArrayKey);
  }

  onCheckedNegativeAdjust(
    formArrayKey: CreateGeneralAdjustmentFormArrayKey | 'taxes',
    formControl: AbstractControl | null,
  ): void {
    formControl?.reset();
    this.recalculatingPreviewAmount(formArrayKey);
  }

  refreshDatesOnMouseDownDatepicker(): void {
    // Refresh the current date
    this.currentDate = new Date();

    // Refresh the min date of Benefit Period End Date
    // Refresh the min date of Accounting Post Date & Transaction Date
    const startDateValue = this.benefitPeriodStartDateControl.value;

    if (DateTime.isDateTime(startDateValue)) {
      this.minEndDate = startDateValue.plus({ days: 1 }).toJSDate();
      this.benefitPeriodStartDateValue = startDateValue.toJSDate();
    } else {
      this.minEndDate = MY_DATE.validMinDate;
      this.benefitPeriodStartDateValue = MY_DATE.validMinDate;
    }
  }

  changeGrossPayment(): void {
    this.earningInfoDisplay.grossPayment = this.fundingSourceFormArray.value.reduce(
      (acc: any, curr: any) => acc + (curr?.amount ?? 0),
      0,
    );
    this.earningInfoDisplay.netPayment = this.earningInfoDisplay.grossPayment - this.earningInfoDisplay.totalDeductions;
  }

  changeTotalDeduction(): void {
    const deductionAmountOfFormArray: number = [
      ...this.insuranceFormArray.value,
      ...this.otherDeductionFormArray.value,
      ...this.garnishmentFormArray.value,
      ...this.qdroDeductionFormArray.value,
    ].reduce((acc: any, curr: any) => acc + (curr?.amount ?? 0), 0);

    this.earningInfoDisplay.totalDeductions =
      (this.taxDeductionFormGroup.value?.amount ?? 0) + deductionAmountOfFormArray;
    this.earningInfoDisplay.netPayment = this.earningInfoDisplay.grossPayment - this.earningInfoDisplay.totalDeductions;
  }

  onChangeAmountField(formArrayKey: CreateGeneralAdjustmentFormArrayKey | 'taxes'): void {
    this.recalculatingPreviewAmount(formArrayKey);
  }

  trackByFunc(index: number, formGroup: AbstractControl | null) {
    return index;
  }

  private recalculatingPreviewAmount(formArrayKey: CreateGeneralAdjustmentFormArrayKey | 'taxes'): void {
    switch (formArrayKey) {
      case CreateGeneralAdjustmentFormArrayKey.FundingSources:
        this.changeGrossPayment();
        break;
      case 'taxes':
      case CreateGeneralAdjustmentFormArrayKey.Insurances:
      case CreateGeneralAdjustmentFormArrayKey.OtherDeductions:
      case CreateGeneralAdjustmentFormArrayKey.Garnishments:
      case CreateGeneralAdjustmentFormArrayKey.QDRODeductions:
        this.changeTotalDeduction();
        break;
      default:
        break;
    }
  }

  private setVisibilityForAddNewButtons(
    isShown: boolean,
    formArrayKey?: CreateGeneralAdjustmentFormArrayKey | DeductionType,
  ): void {
    switch (formArrayKey) {
      case CreateGeneralAdjustmentFormArrayKey.FundingSources:
        this.isHiddenAddFundingSourceButton = !isShown;
        break;
      case CreateGeneralAdjustmentFormArrayKey.Insurances:
      case DeductionType.Insurance:
        this.isHiddenAddInsuranceButton = !isShown;
        break;
      case CreateGeneralAdjustmentFormArrayKey.OtherDeductions:
      case DeductionType.Others:
        this.isHiddenAddOtherDeductionButton = !isShown;
        break;
      case CreateGeneralAdjustmentFormArrayKey.Garnishments:
      case DeductionType.Garnishment:
        this.isHiddenAddGarnishmentButton = !isShown;
        break;
      case CreateGeneralAdjustmentFormArrayKey.QDRODeductions:
      case DeductionType.QDRO:
        this.isHiddenAddQDRODeductionButton = !isShown;
        break;
      default:
        break;
    }
  }

  private get areFundingSourceOrDeductionValid(): boolean {
    return (
      (this.fundingSourceFormArray.length &&
        this.fundingSourceFormArray.controls.some(
          (formGroup) => formGroup.get('fundingSource')?.value && !isEmpty(formGroup.get('amount')?.value),
        )) ||
      (this.isShowTaxDeduction && !isEmpty(this.taxDeductionFormGroup.get('amount')?.value)) ||
      (this.isShowInsurance && !!this.insuranceFormArray.length) ||
      (this.isShowOtherDeduction && !!this.otherDeductionFormArray.length) ||
      (this.isShowGarnishment && !!this.garnishmentFormArray.length) ||
      (this.isShowQdroDeduction && !!this.qdroDeductionFormArray.length)
    );
  }

  private deductionTypeChipsOnChanges(): void {
    this.deductionTypeChipFormArray.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value: Option[]) => {
      const hasAnyTaxDeduction = value.some((option) => option.value === DeductionType.Tax);
      const hasAnyInsurance = value.some((option) => option.value === DeductionType.Insurance);
      const hasAnyOtherDeduction = value.some((option) => option.value === DeductionType.Others);
      const hasAnyGarnishment = value.some((option) => option.value === DeductionType.Garnishment);
      const hasAnyQdroDeduction = value.some((option) => option.value === DeductionType.QDRO);

      this.isShowTaxDeduction = hasAnyTaxDeduction;
      this.isShowInsurance = hasAnyInsurance;
      this.isShowOtherDeduction = hasAnyOtherDeduction;
      this.isShowGarnishment = hasAnyGarnishment;
      this.isShowQdroDeduction = hasAnyQdroDeduction;

      if (!this.isShowTaxDeduction) {
        this.taxDeductionFormGroup.reset({
          ...this.taxDeductionFormGroup.value,
          amount: null,
          isNegativeAdjust: true,
        });
        this.taxDeductionFormGroup.disable();
      } else {
        this.taxDeductionFormGroup.markAsUntouched();
        this.taxDeductionFormGroup.enable();
      }
      if (!this.isShowInsurance) this.insuranceFormArray.clear();
      if (!this.isShowOtherDeduction) this.otherDeductionFormArray.clear();
      if (!this.isShowGarnishment) this.garnishmentFormArray.clear();
      if (!this.isShowQdroDeduction) this.qdroDeductionFormArray.clear();
    });
  }

  private getEarningFundingSources(): void {
    const request: GetEarningFundingSourcesRequest = {
      benefitTypeOptionId: this.data.headerBenefits.benefitTypeOptionId,
    };
    const queryParams: GetEarningFundingSourcesQueryParams = {
      isGetMainFundingSource: true,
      orderByType: OrderByType.ConfigEarning,
    };

    this.payeeDetailStore.dispatch(getEarningFundingSourcesAction({ request, queryParams }));
  }

  private selectEarningFundingSourcesState(): void {
    this.payeeDetailStore
      .select(getEarningFundingSourcesSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.payeeDetailStore.dispatch(clearGetEarningFundingSourcesStateAction());

        const { fundingSourceOptionList, fundingSourceTitle } =
          this.createGeneralAdjustmentComponentService.parsedFundingSourceData(response?.payload);

        this.fundingSourceOptionList = fundingSourceOptionList;
        this.fundingSourceTitle = fundingSourceTitle;
      });
  }

  private getDeductionTypes(): void {
    const { memberId = '', paymentInstruction, headerBenefits } = this.data;

    const request: GetDeductionTypeRequest = {
      payrollBenefitId: paymentInstruction.payrollBenefitId ?? '',
      payeeRecordId: memberId,
      benefitEntityId: headerBenefits.benefitEntityId ?? '',
      benefitSubType: headerBenefits.benefitTypeOptionId ?? '',
    };
    this.payeeDetailStore.dispatch(getGetDeductionTypesAction({ request }));
  }

  private selectDeductionTypesState(): void {
    this.payeeDetailStore
      .select(getDeductionTypeSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.payeeDetailStore.dispatch(clearGetDeductionTypesState());

        const parsedDeductionSubTypeData: ParsedDeductionSubTypeAndBenefitPeriodData | null =
          this.createGeneralAdjustmentComponentService.parsedDeductionTypesAndBenefitPeriodValidationData(
            response?.payload,
          );

        if (parsedDeductionSubTypeData) {
          const {
            deductionTypeOptionList = [],
            taxDeductionItemList = [],
            insuranceOptionList = [],
            otherDeductionOptionList = [],
            garnishmentOptionList = [],
            qdroDeductionOptionList = [],
            qdroDeductionTitle = '',
            hasLumpsumpPension = false,
            isLumpsumpBenefit = false,

            payrollBenefitStartDate: payeeBenefitBeginDate,
            currentBenefitPeriodStartDate,
            doNotHaveFinalizedRecurringPayment = false,
            benefitPeriodEndDateOfLatestFinalizedRecurringPayment,
          } = parsedDeductionSubTypeData;

          this.deductionTypeOptionList = deductionTypeOptionList;
          const { deductionId, deductionType, deductionSubType } = taxDeductionItemList[0];
          this.taxDeductionFormGroup.patchValue({
            deductionId,
            deductionType,
            deductionSubType,
          });
          this.insuranceOptionList = insuranceOptionList;
          this.otherDeductionOptionList = otherDeductionOptionList;
          this.garnishmentOptionList = garnishmentOptionList;
          this.qdroDeductionOptionList = qdroDeductionOptionList;
          this.qdroDeductionTitle = qdroDeductionTitle;
          this.isHiddenBenefitPeriod = isLumpsumpBenefit || hasLumpsumpPension;

          // Do not allow to choose Start Date before the selected Payee's Benefit begin date. If yes, display inline error message: "Start date must be after the Payee's benefit begin date".
          this.minStartDate = payeeBenefitBeginDate ? new Date(payeeBenefitBeginDate) : MY_DATE.validMinDate;

          if (!doNotHaveFinalizedRecurringPayment && benefitPeriodEndDateOfLatestFinalizedRecurringPayment) {
            this.maxEndDate = DateTime.fromISO(benefitPeriodEndDateOfLatestFinalizedRecurringPayment).toJSDate();
          } else if (doNotHaveFinalizedRecurringPayment && currentBenefitPeriodStartDate) {
            this.maxEndDate = DateTime.fromISO(currentBenefitPeriodStartDate).minus({ days: 1 }).toJSDate();
          }
        }
      });
  }

  private getValidateGeneralAdjustment(): void {
    const { memberId = '', paymentInstruction, headerBenefits } = this.data;

    const params: ValidateGeneralAdjustmentParams = {
      payrollBenefitId: paymentInstruction.payrollBenefitId ?? '',
      payeeRecordId: memberId,
      benefitSubType: headerBenefits.benefitTypeOptionId ?? '',
    };

    const deductionItems = this.getDeductionItemsForRequest;
    const body: ValidateGeneralAdjustmentRequest =
      this.createGeneralAdjustmentComponentService.getValidateGeneralAdjustmentBodyRequest(
        this.benefitPeriodStartDateControl.value,
        this.benefitPeriodEndDateControl.value,
        deductionItems,
        this.fundingSourceFormArray.value,
      );

    this.payeeDetailStore.dispatch(getValidateGeneralAdjustmentAction({ params, body }));
  }

  private selectValidateGeneralAdjustmentState(): void {
    this.payeeDetailStore
      .select(validateGeneralAdjustmentSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.payeeDetailStore.dispatch(clearValidateGeneralAdjustmentState());

        if (response?.success === false) {
          // Response to View Detail screen to show banner message
          this.dialogRef.close({ isSuccess: response.success });
          return;
        }

        if (!response?.payload) return;

        const { isValid, errorType, correspondingItems = [] } = response.payload;

        if (isValid) {
          this.getAddGeneralAdjustment();
          return;
        }

        this.handleValidateGeneralAdjustmentError(errorType, correspondingItems);
      });
  }

  private handleValidateGeneralAdjustmentError(
    errorType: ValidateAdjustmentErrorType,
    correspondingItems: CorrespondingItem[],
  ): void {
    switch (errorType) {
      case ValidateAdjustmentErrorType.StartDateMustBeAfterThePayeeBenefitBeginDate:
        this.benefitPeriodStartDateControl.setErrors({
          invalidStartDate: `Start date must be after the Payee's benefit begin date for ${this.data.headerBenefits.benefitName}.`,
        });
        break;
      case ValidateAdjustmentErrorType.StartDateMustMatchStartDateOfAPayrollCycle:
        this.benefitPeriodStartDateControl.setErrors({
          invalidStartDate: 'Start Date must match start date of a payroll cycle.',
        });
        break;
      case ValidateAdjustmentErrorType.StartDateAndEndDateMustBeInSameYear:
        this.benefitPeriodStartDateControl.setErrors({
          invalidStartDate: `Cannot choose Benefit Period's Start Date and End Date in different year.`,
        });
        break;
      case ValidateAdjustmentErrorType.EndDateMustBeBeforeTheLatestFinalizedBenefitPeriod:
      case ValidateAdjustmentErrorType.EndDateMustBeBeforeCurrentTheDayBeforeBenefitPeriodStartDate:
        this.benefitPeriodEndDateControl.setErrors({
          invalidEndDate: `End date must be before the latest finalized benefit period for ${this.data.headerBenefits.benefitName}.`,
        });
        break;
      case ValidateAdjustmentErrorType.EndDateMustBeEndDateOfAPayrollCycle:
        this.benefitPeriodEndDateControl.setErrors({
          invalidEndDate: 'End Date must match end date of a payroll cycle.',
        });
        break;
      case ValidateAdjustmentErrorType.TheNetPositionForCorrespondingItemCannotBeNegativeAfterAdjusted:
        {
          const fundingSourceIdInvalidList: string[] = correspondingItems
            .filter((item) => item.fundingSourceId)
            .map((item) => item.fundingSourceId);
          const deductionItemInvalidList = correspondingItems
            .filter((item) => item.deductionId || item.caseNumber)
            .map((item) => ({
              deductionType: item.deductionType,
              deductionId: item.deductionId,
              caseNumber: item.caseNumber,
            }));

          fundingSourceIdInvalidList.forEach((fundingSourceId) => {
            const invalidFormGroup = this.fundingSourceFormArray.controls.find(
              (formGroup) => fundingSourceId === formGroup.value?.fundingSource?.fundingSourceId,
            );
            if (invalidFormGroup)
              invalidFormGroup.get('amount')?.setErrors({
                invalidAmount: `The net position for ${invalidFormGroup.value?.fundingSource?.fundingSourceName} cannot be negative after adjusted.`,
              });
          });

          deductionItemInvalidList.forEach((deductionItem) => {
            switch (deductionItem.deductionType) {
              case DeductionType.Tax:
                {
                  const invalidFormControl = this.taxDeductionFormGroup.get('amount');
                  if (invalidFormControl) {
                    invalidFormControl.setErrors({
                      invalidAmount: 'The net position for Federal Tax cannot be negative after adjusted.',
                    });
                  }
                }
                break;
              case DeductionType.Insurance:
                {
                  const invalidFormGroup = this.insuranceFormArray.controls.find(
                    (formGroup) => deductionItem.deductionId === formGroup.value?.insurance?.deductionId,
                  );
                  if (invalidFormGroup) {
                    invalidFormGroup.get('amount')?.setErrors({
                      invalidAmount: `The net position for ${invalidFormGroup.value?.insurance?.deductionCode} - ${invalidFormGroup.value?.insurance?.subTypeName} cannot be negative after adjusted.`,
                    });
                  }
                }
                break;
              case DeductionType.Others:
                {
                  const invalidFormGroup = this.otherDeductionFormArray.controls.find(
                    (formGroup) => deductionItem.deductionId === formGroup.value?.otherDeduction?.deductionId,
                  );
                  if (invalidFormGroup) {
                    invalidFormGroup.get('amount')?.setErrors({
                      invalidAmount: `The net position for ${invalidFormGroup.value?.otherDeduction?.deductionCode} - ${invalidFormGroup.value?.otherDeduction?.subTypeName} cannot be negative after adjusted.`,
                    });
                  }
                }
                break;
              case DeductionType.Garnishment:
                {
                  const invalidFormGroup = this.garnishmentFormArray.controls.find(
                    (formGroup) => deductionItem.caseNumber === formGroup.value?.garnishment?.caseNumber,
                  );
                  if (invalidFormGroup) {
                    invalidFormGroup.get('amount')?.setErrors({
                      invalidAmount: `The net position for ${invalidFormGroup.value?.garnishment?.caseNumber} cannot be negative after adjusted.`,
                    });
                  }
                }
                break;
              case DeductionType.QDRO:
                {
                  const invalidFormGroup = this.qdroDeductionFormArray.controls.find(
                    (formGroup) => deductionItem.caseNumber === formGroup.value?.qdroDeduction?.caseNumber,
                  );
                  if (invalidFormGroup) {
                    invalidFormGroup.get('amount')?.setErrors({
                      invalidAmount: `The net position for ${invalidFormGroup.value?.qdroDeduction?.caseNumber} cannot be negative after adjusted.`,
                    });
                  }
                }
                break;
              default:
                break;
            }
          });
        }
        break;
      default:
        break;
    }
  }

  private getAddGeneralAdjustment(): void {
    const { memberId = '', paymentInstruction, headerBenefits } = this.data;

    const params: CreateGeneralAdjustmentParams = {
      payrollBenefitId: paymentInstruction.payrollBenefitId ?? '',
      payeeRecordId: memberId,
      benefitSubType: headerBenefits.benefitTypeOptionId ?? '',
      benefitEntityId: headerBenefits.benefitEntityId ?? '',
    };

    const deductionItems = this.getDeductionItemsForRequest;
    const body: CreateGeneralAdjustmentRequest = {
      ...this.createGeneralAdjustmentComponentService.getValidateGeneralAdjustmentBodyRequest(
        this.benefitPeriodStartDateControl.value,
        this.benefitPeriodEndDateControl.value,
        deductionItems,
        this.fundingSourceFormArray.value,
      ),
      accountingPostDate: this.accountingPostDateControl.value?.toFormat('yyyy-MM-dd') ?? '',
      transactionDate: this.transactionDateControl.value?.toFormat('yyyy-MM-dd') ?? '',
      note: this.reasonControl.value ?? '',
    };

    this.payeeDetailStore.dispatch(getAddGeneralAdjustmentAction({ params, body }));
  }

  private selectAddGeneralAdjustmentState(): void {
    this.payeeDetailStore
      .select(addGeneralAdjustmentSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.payeeDetailStore.dispatch(clearAddGeneralAdjustmentState());

        // Response to View Detail screen to show banner message
        this.dialogRef.close({ isSuccess: !!response?.success, adjustmentId: response?.payload?.adjustmentId ?? '' });
      });
  }

  private get getDeductionItemsForRequest(): any {
    return {
      isShowTaxDeduction: this.isShowTaxDeduction,
      isShowInsurance: this.isShowInsurance,
      isShowOtherDeduction: this.isShowOtherDeduction,
      isShowGarnishment: this.isShowGarnishment,
      isShowQdroDeduction: this.isShowQdroDeduction,
      taxDeductionFormGroupValue: this.taxDeductionFormGroup.value,
      insuranceFormArrayValue: this.insuranceFormArray.value,
      otherDeductionFormArrayValue: this.otherDeductionFormArray.value,
      garnishmentFormArrayValue: this.garnishmentFormArray.value,
      qdroDeductionFormArrayValue: this.qdroDeductionFormArray.value,
    };
  }

  private loadingIndicatorFromStates(): void {
    combineLatest([
      this.payeeDetailStore.select(getEarningFundingSourcesSelector),
      this.payeeDetailStore.select(getDeductionTypeSelector),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((responseList) => {
        this.isLoading = responseList.some((res) => res?.isLoading);
        this.errorOccurs = responseList.some((res) => res?.error);
      });
  }

  get benefitPeriodStartDateControl(): FormControl {
    return this.createForm.get('benefitPeriodStartDate') as FormControl;
  }
  get benefitPeriodEndDateControl(): FormControl {
    return this.createForm.get('benefitPeriodEndDate') as FormControl;
  }
  get fundingSourceFormArray(): FormArray {
    return this.createForm.get(CreateGeneralAdjustmentFormArrayKey.FundingSources) as FormArray;
  }
  get deductionTypeControl(): FormControl {
    return this.createForm.get('deductionType') as FormControl;
  }
  get deductionTypeChipFormArray(): FormArray {
    return this.createForm.get('deductionTypeChips') as FormArray;
  }
  get taxDeductionFormGroup(): FormGroup {
    return this.createForm.get('taxDeduction') as FormGroup;
  }
  get insuranceFormArray(): FormArray {
    return this.createForm.get(CreateGeneralAdjustmentFormArrayKey.Insurances) as FormArray;
  }
  get otherDeductionFormArray(): FormArray {
    return this.createForm.get(CreateGeneralAdjustmentFormArrayKey.OtherDeductions) as FormArray;
  }
  get garnishmentFormArray(): FormArray {
    return this.createForm.get(CreateGeneralAdjustmentFormArrayKey.Garnishments) as FormArray;
  }
  get qdroDeductionFormArray(): FormArray {
    return this.createForm.get(CreateGeneralAdjustmentFormArrayKey.QDRODeductions) as FormArray;
  }
  get accountingPostDateControl(): FormControl {
    return this.createForm.get('accountingPostDate') as FormControl;
  }
  get transactionDateControl(): FormControl {
    return this.createForm.get('transactionDate') as FormControl;
  }
  get reasonControl(): FormControl {
    return this.createForm.get('reason') as FormControl;
  }
}
