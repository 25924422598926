import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AddressData, PayeeElementDetail } from '@ptg-member/features/payee-detail/services/models/add-one-time.model';
import { addPayeeListSelector, paymentAddressSelector } from '@ptg-member/features/payee-detail/store';
import {
  getPayeeListAction,
  getPaymentAddressAction,
} from '@ptg-member/features/payee-detail/store/actions/add-one-time.action';
import { getDepositAccountsAction } from '@ptg-member/store/actions/deposit-account.action';
import { getDepositAccountsState } from '@ptg-member/store/selectors/deposit-account.selector';
import { GetDepositAccountsRequest } from '@ptg-member/types/models/deposit-account.model';
import { navigateToPaymentInformation } from '@ptg-processing/features/one-time-payments/helpers';
import { OneTimePaymentsState } from '@ptg-processing/features/one-time-payments/store/reducers';
import { PaymentType } from '@ptg-processing/features/payroll-calendar-container/types/enums/payroll-deduction-payee.enum';
import { BaseComponent } from '@ptg-shared/components';
import { ENTITY_ORGANIZATION_GUID, GUID_EMPTY } from '@ptg-shared/constance';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Option } from '@ptg-shared/controls/select/select.component';
import { profileNavigationItemRequest } from '@ptg-shared/layout/actions/layout.actions';
import { AddressPipe } from '@ptg-shared/pipes/address.pipe';
import { deepClone, showCancelDialog, showConfirmDialog } from '@ptg-shared/utils/common.util';
import { DateTime } from 'luxon';
import { filter, takeUntil } from 'rxjs/operators';
import { MaskCharacterPipe } from '../../pipes/mask-character.pipe';
import {
  CreateReissueTransactionsRequest,
  GetVoidedReasonDetailsRequest,
  RepresentativePayeeDto,
  TransactionRegister,
  TransactionType,
} from '../../services/models/register.model';
import { clearCreateReissueTransactionsStateAction, createReissueTransactionsAction, getVoidedReasonDetailsAction } from '../../store/actions/register.action';
import { RegisterState } from '../../store/reducers/transaction-register.reducer';
import {
  createReissueTransactionsSelector,
  getVoidedReasonDetailsSelector,
} from '../../store/selectors/register.selector';

const maskCharacter = new MaskCharacterPipe();

@Component({
  selector: 'ptg-reissue-transaction',
  templateUrl: './reissue-transaction.component.html',
  styleUrls: ['./reissue-transaction.component.scss'],
})
export class ReissueTransactionComponent extends BaseComponent {
  readonly TransactionType = TransactionType;
  readonly PaymentType = PaymentType;
  dialogTitle = '';
  accountNo = '';

  listPaymentMethod: Option[] = [
    { value: PaymentType.Check, displayValue: 'Check' },
    { value: PaymentType['Direct Deposit'], displayValue: 'Direct Deposit' },
  ];
  listAccount: Option[] = [];
  listPaymentAddress: Option[] = [];
  listRepresentativePayee: Option[] = [];
  relatedDataList: any;
  isEnableReissueToEstate!: boolean;
  isOneTimeOrRecurring!: boolean;
  isDeductionPayee = false;

  editForm!: FormGroup;
  estateDataList: Option[] = [];
  voidedReasons: string[] = [];

  message = '';
  bannerType: BannerType = BannerType.Hidden;
  clickedRow: undefined | { payeeRecordId: string; payeeEntityId: string; id: string; benefitId: string; benefitCode:string; benefitEntityId: string; };

  isDisabledReissue = false;

  get paymentMethodCtrl(): FormControl {
    return this.editForm?.get('paymentMethod') as FormControl;
  }

  get accountCtrl(): FormControl {
    return this.editForm?.get('account') as FormControl;
  }

  get chkRepresentativePayeeCtrl(): FormControl {
    return this.editForm?.get('chkRepresentativePayee') as FormControl;
  }

  get chkUpdateBankAccountCtrl(): FormControl {
    return this.editForm?.get('chkUpdateBankAccount') as FormControl;
  }

  get chkReissueToEstateCtrl(): FormControl {
    return this.editForm?.get('chkReissueToEstate') as FormControl;
  }

  get representativePayeeCtrl(): FormControl {
    return this.editForm?.get('representativePayee') as FormControl;
  }

  get payeeCtrl(): FormControl {
    return this.editForm?.get('payee') as FormControl;
  }

  get paymentAddressCtrl(): FormControl {
    return this.editForm?.get('paymentAddress') as FormControl;
  }

  get chkPaymentAddressCtrl(): FormControl {
    return this.editForm?.get('chkPaymentAddress') as FormControl;
  }

  get checkMemoCtrl(): FormControl {
    return this.editForm?.get('checkMemo') as FormControl;
  }

  get addendumCtrl(): FormControl {
    return this.editForm?.get('addendum') as FormControl;
  }

  get payableDateCtrl(): FormControl {
    return this.editForm?.get('payableDate') as FormControl;
  }

  constructor(
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<ReissueTransactionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { registerData: TransactionRegister, payeeRecordId?: string, maxLengthCheckNumber?: number, isConfigOST?: boolean },
    private oneTimeStore: Store<OneTimePaymentsState>,
    private readonly address: AddressPipe,
    public store: Store<RegisterState>,
    public router: Router,
  ) {
    super();
    this.isOneTimeOrRecurring =
      data.registerData.transactionType === TransactionType['One-Time'] ||
      data.registerData.transactionType === TransactionType['Recurring'];
    this.isDeductionPayee = data.registerData.transactionType === TransactionType['Deduction Payee'];
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onInitForm();

    if (!this.isDeductionPayee) {
      this.getRepresentativePayeeAction();
      this.handlePayeeAddress();
      this.getBankAccountAction();
    }
    this.getVoidedReasonDetailAction();

    this.getRepresentativePayeeSelector();
    this.getPayeeAddressSelector();
    this.getBankAccountSelector();
    this.getVoidedReasonDetailSelector();
    this.handleCreateReissueTransactionsSelector();
    navigateToPaymentInformation(this, 'id');

    const bankInfo = this.data.registerData?.bankInformation;
    this.accountNo = bankInfo && !bankInfo.isDisabled ? (bankInfo.accountNumber || '') + '-' + bankInfo.bankName : '-';
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(clearCreateReissueTransactionsStateAction());
  }

  getBankAccountAction(targetId?: string) {
    const request: GetDepositAccountsRequest = {
      targetId: targetId ? targetId : this.data.registerData.payeeRecordId,
    };

    this.store.dispatch(getDepositAccountsAction({ request }));
  }

  getBankAccountSelector() {
    this.store.pipe(select(getDepositAccountsState), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state?.success && !state?.isLoading) {
        this.listAccount = (state?.payload?.depositAccounts ?? [])?.filter((el) => el.isActive)
          .map((el) => {
            return {
              value: el.id,
              displayValue: (el.accountNumber || '') + '-' + el.bankName,
            } as Option;
          });
      }
    });
  }

  getPayeeAddressSelector() {
    this.oneTimeStore
      .select(paymentAddressSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        this.listPaymentAddress = deepClone(el.payload || [])
          .sort((a: AddressData, b: AddressData) => {
            if (a.propertyName > b.propertyName) {
              return 1;
            } else if (a.propertyName === b.propertyName) {
              return a.address.street1 > b.address.street1 ? 1 : -1;
            }
            return -1;
          })
          .map((item) => ({
            displayValue: `${item.list ? item.list + '/' : ''}${item.propertyName}`,
            value: item.address.code,
            valueDescription: this.address.transform(item.address),
            displayValueStyle: 'color: #1d7d51; font-weight: 700',
            hasSeparator: true,
            additionalDataConfig: {
              value: this.address.transform(item.address),
              style: 'color: #303030;',
            },
          }));
      });
  }

  getRepresentativePayeeAction() {
    this.oneTimeStore.dispatch(
      getPayeeListAction({
        payeeId: this.data.registerData?.payeeRecordId ?? '',
        benefitCode: this.data.registerData?.benefitCode ?? '',
      }),
    );
  }

  getRepresentativePayeeSelector() {
    this.store
      .select(addPayeeListSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        if (el?.success && !el?.isLoading) {
          this.isEnableReissueToEstate = el.payload?.isAbleReissueToEstate ?? false;
          this.relatedDataList = el.payload?.relatedDataList || [];
          this.listRepresentativePayee = (el.payload?.relatedDataList || []).map((item) => ({
            displayValue: item.name,
            value: item,
            valueDescription: item.relationship,
            extraData: item.isPerson,
          }));
          this.estateDataList = (el.payload?.estateDataList || []).map((item) => ({
            displayValue: item.name,
            value: item,
            valueDescription: item.relationship,
          }));

          const representativePayee = el.payload?.relatedDataList?.find(el => el.id === this.data.registerData.representativePayeeId);
          if (representativePayee) {
              this.representativePayeeCtrl.setValue(representativePayee)
              this.handlePayeeAddress();
          }
        }
      });
  }

  onInitForm() {
    const registerData = this.data.registerData;
    const paymentMethodValue = registerData.paymentMethod!;
    this.dialogTitle = paymentMethodValue === PaymentType.Check ? 'Reissue Check' : 'Reissue Deposit';
    // #159977 Will also check = YES if Payment Method is Direct Deposit,...
    const hasRepresentativePayee = registerData.representativePayeeId != null && registerData.representativePayeeId != GUID_EMPTY;
    const chkRepresentativePayee = new FormControl(hasRepresentativePayee);
    const chkReissueToEstate = new FormControl(false);
    const representativePayee = new FormControl(null);
    const paymentAddress = new FormControl(registerData.addressCode, paymentMethodValue === PaymentType.Check && !this.isDeductionPayee ? Validators.required : null);
    chkRepresentativePayee.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((checked) => {
      representativePayee.setValue(undefined);
      if (checked) {
        representativePayee.setValidators(Validators.required);
        this.handlePayeeAddress();
      } else {
        representativePayee.setValidators(null);
      }
      representativePayee.updateValueAndValidity();
      paymentAddress.setValue(undefined);
    });
    const payee = new FormControl();
    chkReissueToEstate.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((checked) => {
      paymentAddress.setValue(undefined);
      if (checked) {
        payee.setValidators(Validators.required);
        payee.setValue(this.estateDataList[0]?.value);
        this.getBankAccountAction(this.estateDataList[0]?.value?.id);
        this.handlePayeeAddress();
      } else {
        payee.setValidators(null);
        payee.setValue(undefined);
        this.getBankAccountAction();
      }
      payee.updateValueAndValidity();
    });
    const payableDate = new FormControl(DateTime.now().startOf('day'), (control) => {
      if (control.value < DateTime.now().startOf('day')) {
        return { errorMinDate: 'Payable date cannot be past date.' };
      }
      return null;
    });
    // #159977 always enable the check boxes
    const chkPaymentAddress = new FormControl(true);
    const account = new FormControl(registerData.depositId);
    const paymentMethod = new FormControl(paymentMethodValue, Validators.required);
    paymentMethod.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      if (value !== PaymentType['Direct Deposit']) {
        account.clearValidators();
        if (!this.isDeductionPayee) {
          paymentAddress.setValidators(Validators.required);
        }
      } else {
        account.setValidators(Validators.required);
        paymentAddress.clearValidators();
      }
      account.updateValueAndValidity();
      paymentAddress.updateValueAndValidity();
    });
    // #159977 always enable the check boxes
    const chkUpdateBankAccount = new FormControl(true);
    const addendum = registerData.addenda ?? registerData.payeeAddenda?.map(el => el.value).join(' ');
    this.editForm = new FormGroup({
      chkRepresentativePayee,
      chkReissueToEstate,
      representativePayee,
      paymentAddress,
      payableDate,
      payee,
      chkPaymentAddress,
      account,
      chkUpdateBankAccount,
      paymentMethod,
      checkMemo: new FormControl((registerData.checkMemos || []).map(({ memo }) => memo).join(' ')),
      addendum: new FormControl(addendum),
    });
  }

  onSubmit(): void {
    this.editForm.markAllAsTouched();
    if (this.editForm.invalid) {
      return;
    }

    const registerData = this.data.registerData as TransactionRegister;
    if (!registerData) {
      return;
    }
    const actions: string[] = [];
    if (this.chkPaymentAddressCtrl?.value) {
      actions.push('and update this Payment Address to Next Payment');
    }
    else if (this.chkUpdateBankAccountCtrl?.value) {
      actions.push('and update this Bank Account to Next Payment');
    }
    const representativePayeeValue: PayeeElementDetail | undefined = this.chkRepresentativePayeeCtrl?.value
      ? this.representativePayeeCtrl?.value
      : this.payeeCtrl?.value;

    if (representativePayeeValue) {
      actions.push(`for ${representativePayeeValue?.name}`);
    }
    if (actions.length) {
      actions.unshift(' ');
    }
    const paymentType = PaymentType[registerData.paymentMethod!];
    showConfirmDialog(
      this.dialog,
      `Are you sure you want to reissue this ${paymentType}${actions.join(' ')}? This action cannot be undone.`,
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          const datePipe = new DatePipe('en-US');
          const registerData = this.data.registerData;
          const representativePayee: RepresentativePayeeDto = {
            representativePayeeId: representativePayeeValue?.id || '',
            name: `${representativePayeeValue?.name} FBO ${registerData.recipient}`
          };
          // If [Payment Method] = 'direct deposit' so Payment Address is not displayed on screen,
          // BE ignores this and automatically set [Payment Address] = <Primary Address of the Representative Payee>.
          if (registerData.paymentMethod !== PaymentType['Direct Deposit']) {
            representativePayee.paymentAddress = this.paymentAddressCtrl?.value !== null ? this.paymentAddressCtrl?.value : GUID_EMPTY;
          }
          const request: CreateReissueTransactionsRequest = {
            reissuePaymentInstructionId: registerData.paymentInstructionId!,
            paymentType: this.paymentMethodCtrl?.value,
            payableDate: this.payableDateCtrl?.value
              ? datePipe.transform(this.payableDateCtrl.value, 'yyyy-MM-dd')
              : '',
            transactionId: registerData.transactionId,
            estateId: this.chkReissueToEstateCtrl?.value === true ? this.payeeCtrl?.value.id : null,
            isUpdateNextPayment: this.paymentMethodCtrl?.value === PaymentType.Check ? this.chkPaymentAddressCtrl.value : this.chkUpdateBankAccountCtrl.value,
            representativePayee:
              this.chkRepresentativePayeeCtrl?.value === true && !this.chkReissueToEstateCtrl?.value
                ? representativePayee
                : null,
            paymentAddress: this.paymentAddressCtrl?.value !== null ? this.paymentAddressCtrl?.value : GUID_EMPTY,
            checkMemo: this.checkMemoCtrl?.value,
            addendum: this.addendumCtrl?.value,
            depositAccountId: this.accountCtrl.value,
          };
          this.isDisabledReissue = true;
          this.store.dispatch(createReissueTransactionsAction({ request }));
        }
      });
  }

  onCancel(): void {
    showCancelDialog(this.dialog, this.dialogRef);
  }

  getVoidedReasonDetailAction() {
    const request: GetVoidedReasonDetailsRequest = {
      transactionId: this.data.registerData.transactionId,
    };

    this.store.dispatch(getVoidedReasonDetailsAction({ request }));
  }

  getVoidedReasonDetailSelector() {
    this.store
      .select(getVoidedReasonDetailsSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.success && !state?.isLoading) {
          this.voidedReasons = (state?.payload ?? []).map((reason) => reason.reasonOptionDescription);
        }
      });
  }

  handleCreateReissueTransactionsSelector() {
    this.store
      .select(createReissueTransactionsSelector)
      .pipe(
        filter((r) => !!r && !r.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (response?.success) {
          this.bannerType = BannerType.Success;
          this.message = 'Transaction successfully reissued.';
          const registerData = this.data.registerData;

          this.clickedRow = {
            payeeRecordId: registerData.payeeRecordId,
            payeeEntityId: registerData.payeeEntityId,
            id: response.payload!,
            benefitId: registerData.benefitId,
            benefitCode: registerData.benefitCode,
            benefitEntityId: registerData.benefitEntityId ?? '',
          };
          if (!this.isDeductionPayee) {
            this.store.dispatch(
              profileNavigationItemRequest({
                memberId: this.clickedRow.payeeRecordId,
                entityReferenceLinkedId: this.clickedRow.payeeEntityId,
                queryParams: {
                  benefitCode: this.clickedRow.benefitCode,
                  benefitEntityId: this.clickedRow.benefitEntityId ?? '',
                },
              }),
            );
          } else {
            this.dialogRef.close({ success: true });
          }
          return;
        }
        if (response?.error !== undefined) {
          this.bannerType = BannerType.Fail;
          this.message = 'Error occurred reissuing transaction. Please try again.';
        }
        this.isDisabledReissue = false;
      });
  }

  beforeNavigate() {
    this.dialogRef.close();
  }

  handlePayeeAddress() {
    this.listPaymentAddress = [];
    if (this.chkReissueToEstateCtrl?.value) {
      if (this.payeeCtrl?.value) {
        const registerData = this.data.registerData;
        const payee = this.payeeCtrl.value;
        this.oneTimeStore.dispatch(
          getPaymentAddressAction({
            payeeId: payee.id,
            isPerson: payee.isPerson,
            benefitCode: registerData.benefitCode ?? '',
          }),
        );
      }
      return;
    }
    if (this.chkRepresentativePayeeCtrl?.value && this.representativePayeeCtrl?.value) {
      const registerData = this.data.registerData;
      const payee = this.representativePayeeCtrl.value;
      if (payee !== GUID_EMPTY) {
        this.oneTimeStore.dispatch(
          getPaymentAddressAction({
            payeeId: payee.id,
            isPerson: payee.isPerson,
            benefitCode: registerData.benefitCode ?? '',
          }),
        );
      }
      return;
    }
    const registerData = this.data.registerData;
    this.oneTimeStore.dispatch(
      getPaymentAddressAction({
        payeeId: registerData.payeeRecordId ?? '',
        isPerson: registerData.payeeEntityId !== ENTITY_ORGANIZATION_GUID,
        benefitCode: registerData.benefitCode ?? '',
      }),
    );
  }

  formatCheckNumberOST(value: any){
    if(value){
      const endingChar = this.data?.registerData?.endingChar?.trim();
      if (!endingChar) {
        return value;
      }
      let numericPart = value?.toString()?.trim().replace(endingChar, '');
      let paddedNumericPart = numericPart?.padStart(this.data.maxLengthCheckNumber, '0');
      return paddedNumericPart + endingChar;
    }
    return '';
  }

  onChangeReissueToEstate(event: any) {
    if (event) {
      this.chkRepresentativePayeeCtrl.setValue(false);
    }
  }

  onChangePaymentMethod(event: any) {
    if (this.paymentMethodCtrl?.value === PaymentType['Direct Deposit']) {
      this.chkRepresentativePayeeCtrl.setValue(false);
    } else if (this.data.registerData.representativePayeeId !== GUID_EMPTY) {
      this.chkRepresentativePayeeCtrl.setValue(true);
      const representativePayee = this.relatedDataList?.find((el: any) => el.id === this.data.registerData.representativePayeeId);
      if (representativePayee) {
          this.representativePayeeCtrl.setValue(representativePayee)
          this.handlePayeeAddress();
      } else {
        this.representativePayeeCtrl.setErrors(null);
      }
    }
  }
}
